<template>
  <form-section v-if="offerSectionComplete" :id="sectionId" class="credit-section"
    @visibility="onChangeSectionVisibility">
    <!-- Limited company -->
    <form-group v-if="isLimitedCompany" :is-visible="currentQuestion === isCompanyDirectorId" @visibility="isVisible => onChangeQuestionVisibility(isVisible, isCompanyDirectorId)
      ">
      <mobile-full-page-wrapper :go-to-id="isCompanyDirectorId" show-arrow>
        <credit-check-header />
      </mobile-full-page-wrapper>

      <mobile-full-page-wrapper :id="isCompanyDirectorId">
        <v-layout mt-4 fill-height column justify-space-between>
          <!-- Is company director title -->
          <v-flex>
            <typography type="h5">Are you a company director?</typography>
          </v-flex>
          <!-- Is company director buttons -->
          <v-flex>
            <toggle-button-wrapper>
              <toggle-button :active="isCompanyDirector === true" :value="true" label="Yes" name="IsDirectorButton"
                @click="onChangeIsDirector" />
              <toggle-button :active="isCompanyDirector === false" :value="false" label="No" name="IsNotDirectorButton"
                @click="onChangeIsDirector" />
            </toggle-button-wrapper>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>

      <!-- Non director requirements for Limited company -->
      <mobile-full-page-wrapper v-if="isCompanyDirector === false" :id="representativeRequirementsId">
        <v-layout>
          <v-flex>
            <!-- Representative requirements header -->
            <v-layout mb-3>
              <v-flex sm12 md9>
                <typography type="h5">All good!</typography>
                <typography light>
                  As the representative, you’ll just need to make sure you fill
                  out the next few questions on behalf of the Company Director.
                </typography>
              </v-flex>
            </v-layout>
            <!-- Representative requirements -->
            <v-layout>
              <v-flex sm12 md9>
                <credit-check-requirements />
              </v-flex>
            </v-layout>
            <!-- Representative requirements note -->
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mt-4>
              <v-flex xs10 sm12 md9 lg7 offset-lg1>
                <typography align="center" type="small-p">
                  Don’t have your Company Director details on hand? No worries,
                  click
                  <a href="#" @click.prevent="onSaveAndShareClick" name="HasDirectorDetailsLink">save and share</a>
                  to resume this form at any time, or even hand over to someone
                  else to complete.
                </typography>
              </v-flex>
            </v-layout>

            <!-- Non director requirements next button -->
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" :mt-4="$vuetify.breakpoint.xsOnly"
              :mt-5="$vuetify.breakpoint.smAndUp">
              <v-flex xs9 sm6>
                <round-button v-if="!readRepresentativeRequirements" outline
                  @click="onRepresentativeRequirementsCompleteClick">Next</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>
    <!-- End limited company only -->
    <!-- Legal name + start of non limited company -->
    <form-group v-if="
      !isLimitedCompany || isCompanyDirector || readRepresentativeRequirements
    " :is-visible="currentQuestion === legalNameId" @visibility="isVisible => onChangeQuestionVisibility(isVisible, legalNameId)
      ">
      <!-- Only show for non limited company, otherwise limited company will see this twice -->
      <mobile-full-page-wrapper v-if="!isLimitedCompany" :go-to-id="legalNameId" show-arrow>
        <credit-check-header />
      </mobile-full-page-wrapper>

      <!-- Legal name -->
      <mobile-full-page-wrapper :id="legalNameId">
        <v-layout fill-height column justify-space-between>
          <!-- Legal name title -->
          <v-flex mb-1>
            <typography type="h5" v-if="isLimitedCompany && !isCompanyDirector">What is the director's legal name?
            </typography>
            <typography type="h5" v-else>What is your legal name?</typography>
          </v-flex>
          <!-- Legal name text input -->
          <v-flex>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm9 md7>
                <combobox v-if="isLimitedCompany" v-model="legalName" item-text="fullName" item-value="fullName"
                  :items="directors" :rules="[
                    () => $v.legalName.required || 'This field is required'
                  ]" label="Full name" name="LegalName" no-data-text="Search directors" no-margin
                  @blur="$v.legalName.$touch()" />
                <text-input v-else v-model="legalName" :rules="[
                  () => $v.legalName.required || 'This field is required',
                  () => $v.legalName.maxLength || 'This field is invalid'
                ]" :maxlength="250" label="Full name" name="LegalName" @blur="$v.legalName.$touch()" />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="businessRegisterAddress" mt-4>
            <h4 class="bold">
              Your residential address is: {{
                businessRegisterAddress.fullAddress }}

            </h4>
            <p v-if="!skipCreditCheck" light>This residential address will be used for credit check purposes.</p>

            <p class="mt-2">
              If this address is wrong, please contact the relevant Register.
              <tooltip :top="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.xsOnly">

                <icon-button slot="activator">
                  <help-icon />
                </icon-button>

                <div class="address-help">
                  <typography type="small-p" no-margin>
                    This could be the Companies Register, NZBN Register or another Register.
                  </typography>
                </div>
              </tooltip>
              <br>
              You can select an alternate card delivery address later in this application.
            </p>
          </v-flex>

          <!-- Legal name next button -->
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm6 mt-5>
                <round-button v-if="!legalNameComplete" :disabled="$v.$invalid" outline
                  @click="onLegalNameCompleteClick">Next</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Gender -->
    <form-group v-if="legalNameComplete" :is-visible="currentQuestion === genderId"
      @visibility="isVisible => onChangeQuestionVisibility(isVisible, genderId)">
      <mobile-full-page-wrapper :id="genderId">
        <v-layout fill-height column justify-space-between>
          <v-flex>
            <v-layout>
              <v-flex md8 lg6>
                <typography type="h5" v-if="isLimitedCompany && !isCompanyDirector">What is the director's gender?
                </typography>
                <typography type="h5" v-else>What is your gender?</typography>
                <typography v-if="$vuetify.breakpoint.smAndUp" :type="$vuetify.breakpoint.xsOnly
                  ? 'small-p'
                  : 'small-caption-text'
                  " light>
                  We know this is an unusual question, but the more information
                  we have, the more accurate the credit report will be.
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <toggle-button-wrapper>
              <toggle-button v-for="(label, value) in genderOptions" :key="value" :active="gender === value"
                :value="value" :label="label" :name="`${value}Button`" @click="onChangeGender" />
            </toggle-button-wrapper>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Date of birth -->
    <form-group v-if="gender" :is-visible="currentQuestion === dobId"
      @visibility="isVisible => onChangeQuestionVisibility(isVisible, dobId)">
      <mobile-full-page-wrapper :id="dobId">
        <v-layout fill-height column justify-space-between>
          <!-- Date of birth title -->
          <v-flex>
            <typography type="h5" v-if="isLimitedCompany && !isCompanyDirector">What is the director's date of birth?
            </typography>
            <typography type="h5" v-else>What is your date of birth?</typography>
          </v-flex>
          <!-- Date of birth text input -->
          <v-flex>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm6 md4 lg3>
                <date-picker v-model="dateOfBirth" :rules="[
                  () => $v.dateOfBirth.required || 'A valid date is required',
                  () => $v.dateOfBirth.date || 'A valid date is required',
                  () => $v.dateOfBirth.minValue || 'This field is invalid',
                  () =>
                    $v.dateOfBirth.maxValue ||
                    `Sorry, ${this.isLimitedCompany ? 'the director' : 'you'
                    } must be older than 18 to complete this application`
                ]" label="Date of birth" name="DateOfBirth" activePicker="YEAR" :min="minDateOfBirth"
                  :max="maxDateOfBirth" @input="$v.dateOfBirth.$touch()" />
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Date of birth next button -->
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm5 mt-5>
                <round-button v-if="!dateOfBirthComplete" :disabled="$v.$invalid" outline
                  @click="onDateOfBirthCompleteClick">Next</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Drivers licence -->
    <form-group v-if="dateOfBirthComplete" :is-visible="currentQuestion === driversLicenseId" @visibility="isVisible => onChangeQuestionVisibility(isVisible, driversLicenseId)
      ">
      <mobile-full-page-wrapper :id="driversLicenseId">
        <v-layout fill-height column justify-space-between>
          <!-- Driver licence title -->
          <v-flex mb-4>
            <typography inline type="h5">
              <span v-if="isLimitedCompany && !isCompanyDirector">What are the director's driver licence details?</span>
              <span v-else>What are your driver licence details?</span>
              <tooltip :top="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.xsOnly">
                <icon-button slot="activator">
                  <help-icon />
                </icon-button>
                <driver-license-help />
              </tooltip>
            </typography>
          </v-flex>
          <!-- Driver license fields -->
          <v-flex>
            <v-layout>
              <v-flex xs12 sm10 md9 lg7>
                <v-container class="license-details" grid-list-xl>
                  <v-layout wrap>
                    <v-flex xs12 md4>
                      <text-input v-model="licenseNumber" :rules="[
                        () =>
                          $v.licenseNumber.required ||
                          'This field is required',
                        () =>
                          $v.licenseNumber.alphaNum ||
                          'Licence number is not valid',
                        () =>
                          $v.licenseNumber.minLength ||
                          'Licence number is not valid',
                        () =>
                          $v.licenseNumber.maxLength ||
                          'Licence number is not valid'
                      ]" :maxlength="9" label="Licence number" name="LicenceNumber" no-margin hint="E.g. AB123456"
                        @blur="$v.licenseNumber.$touch()" />
                    </v-flex>
                    <v-flex xs12 md4>
                      <text-input v-model="licenseVersion" :rules="[
                        () =>
                          $v.licenseVersion.required ||
                          'This field is required',
                        () =>
                          $v.licenseVersion.alphaNum ||
                          'Card version no is not valid',
                        () =>
                          $v.licenseVersion.minLength ||
                          'Card version no is not valid',
                        () =>
                          $v.licenseVersion.maxLength ||
                          'Card version no is not valid'
                      ]" :maxlength="3" label="Card version no" name="LicenseVersion" no-margin pattern="\d*"
                        hint="E.g. 123" @blur="$v.licenseVersion.$touch()" />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- Driver license next button -->
          <v-flex class="no-grow" mt-5>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm6>
                <round-button v-if="!driversLicenseComplete" :disabled="$v.$invalid" outline
                  @click="onDriversLicenseCompleteClick">Next</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Residential address -->
    <form-group v-if="driversLicenseComplete" :is-visible="currentQuestion === residentialAddressId" @visibility="isVisible => onChangeQuestionVisibility(isVisible, residentialAddressId)
      ">
      <mobile-full-page-wrapper :id="residentialAddressId">
        <v-layout fill-height column justify-space-between>
          <!-- Residential address title -->
          <v-flex v-if="!businessRegisterAddress">
            <v-layout>
              <v-flex md6>
                <typography type="h5" v-if="isLimitedCompany && !isCompanyDirector">What is the director's residential
                  address?</typography>
                <typography type="h5" v-else>What is your residential address?</typography>
                <typography :type="$vuetify.breakpoint.xsOnly ? 'small-p' : 'small-caption-text'"
                  v-if="!skipCreditCheck" light>This residential address will be used for credit check purposes.
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Residential address text fields -->
          <v-flex>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" v-if="!businessRegisterAddress">
              <v-flex xs11 sm8 md7>
                <autocomplete v-if="!hasManualResidentialAddress" v-model="residentialAddress"
                  :loading="isSearchingResidentialAddress" :items="residentialAddressLookupResponse"
                  :text-value="residentialAddressText" :rules="[
                    () =>
                      $v.residentialAddress.required || 'This field is required'
                  ]" item-text="fullAddress" item-value="fullAddress" label="Residential address"
                  name="ResidentialAddressSearch" no-data-text="Search for your address"
                  trouble-text="Having trouble finding your address?" no-margin
                  @text-input="onChangeResidentialAddressText" @search="onResidentialAddressSearch"
                  @manual="onUseManualResidentialAddress" @blur="$v.residentialAddress.$touch()" />
                <text-input v-if="hasManualResidentialAddress" v-model="residentialAddressText" :rules="[
                  () =>
                    $v.residentialAddressText.required ||
                    'This field is required'
                ]" label="Residential address" name="ResidentialAddress" no-margin
                  @blur="$v.residentialAddressText.$touch()" />
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Residential address checkbox and next button -->
          <v-flex :mt-5="$vuetify.breakpoint.mdAndUp">
            <!-- Checkbox -->
            <v-layout>
              <v-flex xs12 sm10 md6 lg5>
                <box v-if="!skipCreditCheck && !creditSectionComplete">
                  <checkbox v-model="agreedToCreditCheck" :label="`${legalName} consents to a credit check being performed by or on behalf of Z Energy Limited`
                    " name="AgreeToCreditCheck" />
                </box>
              </v-flex>
            </v-layout>
            <!-- Next section button -->
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm6 md12>
                <round-button v-if="!creditSectionComplete" :disabled="$v.$invalid" @click="onNextSectionClick">Next:
                  Final details</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>
  </form-section>
</template>

<script>
import FormGroup from '@/components/atoms/FormGroup.vue';
import Typography from '@/components/atoms/Typography.vue';
import Box from '@/components/atoms/Box.vue';
import ToggleButtonWrapper from '@/components/atoms/ToggleButtonWrapper.vue';
import ToggleButton from '@/components/molecules/ToggleButton.vue';
import CreditCheckRequirements from '@/components/molecules/CreditCheckRequirements.vue';
import CreditCheckHeader from '@/components/molecules/CreditCheckHeader.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import Autocomplete from '@/components/molecules/Autocomplete.vue';
import Combobox from '@/components/molecules/Combobox.vue';
import IconButton from '@/components/atoms/IconButton.vue';
import DriverLicenseHelp from '@/components/molecules/DriverLicenseHelp.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import HelpIcon from '@/assets/icons/help.svg';
import {
  SECTION_CREDIT_CHECK,
  SECTION_FINAL_DETAILS,
  REPRESENTATIVE_REQUIREMENTS,
  LEGAL_NAME,
  GENDER,
  DRIVERS_LICENSE,
  DOB,
  RESIDENTIAL_ADDRESS,
  IS_COMPANY_DIRECTOR
} from '@/constants/html-ids';
import { GENDER_OPTIONS } from '@/constants/form';
import { mapGetters, mapState } from 'vuex';
import {
  requiredIf,
  sameAs,
  alphaNum,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';
import { date } from '@/validators';
import { navigationOffset } from '@/helpers/navigation';
import { getDateOfBirth } from '@/helpers/date';

export default {
  components: {
    Box,
    Combobox,
    CreditCheckHeader,
    FormGroup,
    Typography,
    ToggleButtonWrapper,
    ToggleButton,
    MobileFullPageWrapper,
    CreditCheckRequirements,
    RoundButton,
    TextInput,
    DatePicker,
    Checkbox,
    Autocomplete,
    Tooltip,
    HelpIcon,
    IconButton,
    DriverLicenseHelp,
    FormSection
  },
  computed: {
    residentialAddress: {
      get() {
        return this.$store.state.credit.residentialAddress;
      },
      set(value) {
        this.$store.dispatch('credit/changeResidentialAddress', value);
      }
    },
    residentialAddressText: {
      get() {
        return this.$store.state.credit.residentialAddressText;
      },
      set(value) {
        this.$store.dispatch('credit/changeResidentialAddressText', value);
      }
    },
    businessRegisterAddress: {
      get() {
        return this.$store.state.business?.business?.roles.find(role => `${role.rolePerson.firstName} ${role.rolePerson.lastName}` == this.legalName)?.roleAddress.at(0)
      }
    },
    legalName: {
      get() {
        return this.$store.state.credit.legalName;
      },
      set(value) {
        this.$store.dispatch('credit/changeLegalName', value);
      }
    },
    agreedToCreditCheck: {
      get() {
        return this.$store.state.credit.agreedToCreditCheck || this.skipCreditCheck;
      },
      set(value) {
        this.$store.dispatch('credit/changeAgreedToCreditCheck', value);
      }
    },
    licenseNumber: {
      get() {
        return this.$store.state.credit.driversLicenseNumber;
      },
      set(value) {
        this.$store.dispatch('credit/changeDriversLicenseNumber', value);
      }
    },
    licenseVersion: {
      get() {
        return this.$store.state.credit.driversLicenseVersionNo;
      },
      set(value) {
        this.$store.dispatch('credit/changeDriversLicenseVersionNo', value);
      }
    },
    dateOfBirth: {
      get() {
        return this.$store.state.credit.dateOfBirth;
      },
      set(value) {
        this.$store.dispatch('credit/changeDateOfBirth', value);
      }
    },
    minDateOfBirth: () => getDateOfBirth(130),
    maxDateOfBirth: () => getDateOfBirth(18),
    sectionId: () => SECTION_CREDIT_CHECK,
    isCompanyDirectorId: () => IS_COMPANY_DIRECTOR,
    representativeRequirementsId: () => REPRESENTATIVE_REQUIREMENTS,
    legalNameId: () => LEGAL_NAME,
    genderId: () => GENDER,
    dobId: () => DOB,
    residentialAddressId: () => RESIDENTIAL_ADDRESS,
    driversLicenseId: () => DRIVERS_LICENSE,
    genderOptions: () => GENDER_OPTIONS,
    ...mapState({
      businessType: state => state.business.businessType,
      gender: state => state.credit.gender,
      offerSectionComplete: state => state.offer.sectionComplete,
      creditSectionComplete: state => state.credit.sectionComplete,
      legalNameComplete: state => state.credit.legalNameComplete,
      dateOfBirthComplete: state => state.credit.dateOfBirthComplete,
      driversLicenseComplete: state => state.credit.driversLicenseComplete,
      residentialAddressComplete: state =>
        state.credit.residentialAddressComplete,
      isCompanyDirector: state => state.credit.isCompanyDirector,
      readRepresentativeRequirements: state =>
        state.credit.readRepresentativeRequirements,
      isSearchingResidentialAddress: state =>
        state.progress.residentialAddressLookup.inProgress,
      residentialAddressLookupResponse: state =>
        state.credit.residentialAddressLookupResponse,
      hasManualResidentialAddress: state =>
        state.credit.hasManualResidentialAddress
    }),
    ...mapGetters({
      firstName: 'landing/firstName',
      directors: 'business/directors',
      tradingNameWithBusinessNameFallback:
        'business/tradingNameWithBusinessNameFallback',
      currentQuestion: 'navigation/currentQuestion',
      isLimitedCompany: 'business/isLimitedCompany',
      skipCreditCheck: 'offer/skipCreditCheck'
    })
  },
  methods: {
    navigationOffset(htmlId) {
      if (this.$vuetify.breakpoint.smAndUp) {
        return navigationOffset(htmlId);
      }

      return 0;
    },
    async onChangeIsDirector(value) {
      this.$store.dispatch('credit/changeIsCompanyDirector', value);

      await this.$nextTick();

      if (value) {
        this.$vuetify.goTo(`#${LEGAL_NAME}`, {
          offset: this.navigationOffset(LEGAL_NAME)
        });
      } else {
        this.$vuetify.goTo(`#${REPRESENTATIVE_REQUIREMENTS}`, {
          offset: this.navigationOffset(REPRESENTATIVE_REQUIREMENTS)
        });
      }
    },
    async onRepresentativeRequirementsCompleteClick() {
      this.$store.dispatch('credit/changeReadRepresentativeRequirements', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${LEGAL_NAME}`, {
        offset: this.navigationOffset(LEGAL_NAME)
      });
    },
    async onChangeGender(value) {
      this.$store.dispatch('credit/changeGender', value);

      await this.$nextTick();

      this.$vuetify.goTo(`#${DOB}`, {
        offset: this.navigationOffset(DOB)
      });
    },
    async onLegalNameCompleteClick() {
      this.$store.dispatch('credit/changeLegalNameComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${GENDER}`, {
        offset: this.navigationOffset(GENDER)
      });
    },
    async onDateOfBirthCompleteClick() {
      this.$store.dispatch('credit/changeDateOfBirthComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${DRIVERS_LICENSE}`, {
        offset: this.navigationOffset(DRIVERS_LICENSE)
      });
    },
    async onDriversLicenseCompleteClick() {
      this.$store.dispatch('credit/changeDriversLicenseComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${RESIDENTIAL_ADDRESS}`, {
        offset: this.navigationOffset(RESIDENTIAL_ADDRESS)
      });
    },
    async onNextSectionClick() {
      this.$store.dispatch('credit/changeSectionComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${SECTION_FINAL_DETAILS}`, {
        offset: this.navigationOffset(SECTION_FINAL_DETAILS)
      });
    },
    onChangeResidentialAddressText(value) {
      this.$store.dispatch('credit/changeResidentialAddressText', value);
    },
    onResidentialAddressSearch() {
      this.$store.dispatch('credit/residentialAddressLookup');
    },
    onUseManualResidentialAddress() {
      this.$store.dispatch('credit/changeHasManualResidentialAddress', true);
    },
    onSaveAndShareClick() {
      this.$store.dispatch('saver/saveDraft');
      this.$store.dispatch('ui/toggleShareDialog', true);
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch(
          'navigation/addSectionInView',
          SECTION_CREDIT_CHECK
        );
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_CREDIT_CHECK
        );
      }
    },
    onChangeQuestionVisibility(isVisible, htmlId) {
      if (isVisible) {
        this.$store.dispatch('navigation/addQuestionInView', htmlId);
      } else {
        this.$store.dispatch('navigation/removeQuestionInView', htmlId);
      }
    }
  },
  validations: {
    legalName: {
      required: requiredIf(
        nestedModel =>
          !nestedModel.isLimitedCompany ||
          nestedModel.isCompanyDirector === true ||
          nestedModel.readRepresentativeRequirements
      ),
      maxLength: maxLength(250)
    },
    dateOfBirth: {
      required: requiredIf(nestedModel => !!nestedModel.gender),
      date,
      minValue(value) {
        return !value || value >= this.minDateOfBirth;
      },
      maxValue(value) {
        return !value || value <= this.maxDateOfBirth;
      }
    },
    licenseNumber: {
      required: requiredIf(nestedModel => nestedModel.dateOfBirthComplete),
      alphaNum,
      minLength: minLength(8),
      maxLength: maxLength(9)
    },
    licenseVersion: {
      required: requiredIf(nestedModel => nestedModel.dateOfBirthComplete),
      alphaNum,
      minLength: minLength(3),
      maxLength: maxLength(3)
    },
    residentialAddress: {
      required: requiredIf(
        nestedModel =>
          !nestedModel.hasManualResidentialAddress &&
          nestedModel.driversLicenseComplete &&
          !nestedModel.businessRegisterAddress
      )
    },
    residentialAddressText: {
      required: requiredIf(
        nestedModel =>
          nestedModel.hasManualResidentialAddress &&
          nestedModel.driversLicenseComplete &&
          !nestedModel.businessRegisterAddress
      )
    },
    agreedToCreditCheck: {
      sameAs: sameAs(nestedModel => {
        // If the credit check isn't required or
        // the user is not up to the credit check section,
        // return the value it is so isValid = true
        if (nestedModel.skipCreditCheck ||
          !nestedModel.driversLicenseComplete) {
          return nestedModel.agreedToCreditCheck;
        }

        // Else agreedToCreditCheck should be checked
        return true;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.license-details {
  padding: 0;
}

.address-help {
  max-width: 100%;
  width: rem(440px);
  color: $charcoal;
}
</style>
