<template>
  <div class="payment-plans">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.payment-plans {
  display: flex;
  margin: rem(30px 0 30px 0);
  flex-wrap: wrap;
}
</style>
