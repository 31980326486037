<template>
  <div
    :class="['payment-plan', { 'payment-plan--active': active }]"
    @click="onClick"
  >
    <typography type="h4" class="payment-plan__title">{{ title }}</typography>

    <div class="payment-plan__content">
      <div class="payment-plan__text">
        <span class="payment-plan__amount">{{ amount }}</span>
        <typography class="payment-plan__suffix" no-margin inline>{{
          suffix
        }}</typography>
      </div>

      <round-button
        v-if="$vuetify.breakpoint.smAndUp"
        :color="active ? 'white' : 'orange'"
        class="payment-plan__button"
        >{{ buttonText }}</round-button
      >
    </div>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';

export default {
  components: {
    Typography,
    RoundButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    amount: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.payment-plan {
  cursor: pointer;
  background: $white;
  box-shadow: 0 0 20px 0 rgba(152, 152, 152, 0.2);
  border-radius: rem(10px);
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: rem(20px);
  user-select: none;

  @include sm {
    width: rem(180px);
    flex-direction: column;
    margin-right: rem(20px);
  }

  @include md {
    width: rem(220px);
  }

  &--active {
    background: $mid-orange;
  }

  &__title {
    color: $mid-orange;
    margin-bottom: 0;
    padding: rem(15px 20px);

    @include sm {
      border-bottom: 1px solid #f8f8f8;
    }

    .payment-plan--active & {
      color: $white;
      border-bottom: 1px solid rgba(#f8f8f8, 0.2);
    }
  }

  &__content {
    padding: rem(20px);
    color: $orange;

    @include sm {
      width: 100%;
      padding: rem(20px 15px);
    }

    @include md {
      padding: rem(30px);
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    line-height: normal;
    color: $charcoal;

    @include sm {
      margin-bottom: rem(35px);
    }

    .payment-plan--active & {
      color: $white;
    }
  }

  &__amount {
    font-size: rem(38px);
    font-weight: $weight-light;

    @include sm {
      font-size: rem(60px);
    }
  }
}
</style>
