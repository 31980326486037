import { render, staticRenderFns } from "./CardSettingsInfoBox.vue?vue&type=template&id=2bfb5890&scoped=true&"
import script from "./CardSettingsInfoBox.vue?vue&type=script&lang=js&"
export * from "./CardSettingsInfoBox.vue?vue&type=script&lang=js&"
import style0 from "./CardSettingsInfoBox.vue?vue&type=style&index=0&id=2bfb5890&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bfb5890",
  null
  
)

export default component.exports