<template>
  <v-select
    :items="items"
    :label="label"
    :rules="rules"
    :value="value"
    :item-text="itemText"
    :item-value="itemValue"
    :error-messages="errorMessages"
    append-icon="expand_more"
    class="select-input"
    :menu-props="{ contentClass: 'select-content' }"
    @input="onInput"
    @change="onChange"
  />
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: undefined
    },
    value: {
      type: null,
      default: undefined
    },
    rules: {
      type: Array,
      default: () => []
    },
    itemText: {
      type: String,
      default: undefined
    },
    itemValue: {
      type: String,
      default: undefined
    },
    errorMessages: {
      type: Array,
      default: undefined
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

// Not scoped because select-content from v-select component doesn't take scoped classes

.select-content {
  .v-list {
    padding: 0;

    &__tile {
      color: $charcoal;
      padding: rem(0 25px);
      font-size: rem(20px);
      height: rem(70px);

      &:hover {
        background-color: #f8f8f8;
        text-decoration: none;
      }

      &__content {
        border-bottom: 1px solid #e4e4e4;
      }
    }
  }
}

.select-input {
  font-size: rem(20px);

  .v-label {
    color: $charcoal;
    font-size: rem(20px);

    &.error--text {
      color: $error-color;
    }

    &--active {
      transform: translateY(-18px) scale(0.6);
      font-weight: $weight-medium;
    }
  }

  &.error--text {
    color: $error-color !important;

    .v-input__slot {
      &:before {
        border-color: $error-color !important;
      }

      &:after {
        color: $error-color !important;
      }
    }
  }

  .v-input__slot {
    &:before {
      border-color: $mid-charcoal;
      border-width: 1px;
    }

    &:after {
      color: currentColor;
      border-width: 1px;
    }

    &:hover {
      &:before {
        border-color: $charcoal;
      }
    }
  }

  .v-icon {
    &.error--text {
      color: $error-color;
    }
  }

  .v-messages {
    min-height: rem(15px);
    font-size: rem(15px);
    color: $mid-charcoal !important;

    &.error--text {
      color: $error-color !important;
    }
  }

  .v-select {
    &__selection {
    }
  }
}
</style>
