<template>
  <div class="offer-carousel">
    <v-carousel
      v-if="value"
      :hide-controls="!hasMultipleDiscounts"
      :hide-delimiters="!hasMultipleDiscounts"
      :cycle="hasMultipleDiscounts"
      height=""
      interval="5000"
    >
      <v-carousel-item v-for="(item, i) in value" :value="i" :key="i">
        <fuel-offer :item="item"></fuel-offer>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import FuelOffer from './FuelOffer';
export default {
  components: {
    FuelOffer
  },
  props: {
    value: {
      type: Array,
      default: null
    }
  },
  computed: {
    hasMultipleDiscounts() {
      return this.value && this.value.length > 1;
    }
  }
};
</script>

<style scoped>
.offer-carousel {
  background-image: linear-gradient(-180deg, #271d90 0%, #1e196a 100%);
  border-radius: 20px;
  height: 282px;
  margin-bottom: 3.125rem;
  position: relative;
}

.offer-carousel /deep/ .v-carousel {
  box-shadow: none;
  height: 100% !important;
}

.offer-carousel /deep/ .v-carousel__controls {
  background: none;
}
</style>
