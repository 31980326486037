<template>
  <box class="info-box">
    <div v-if="$vuetify.breakpoint.mdAndUp" class="card-box">
      <z-card black-shadow />
    </div>
    <div class="text-box">
      <typography class="text" type="small-p" no-margin>
        {{ brand }} fuel cards come preset with
        <tooltip top>
          <a slot="activator" href="#" @click.prevent
            >Any vehicle, Any driver</a
          >
          <div class="tooltip-content">
            <typography type="small-p" bold no-margin
              >Any vehicle, any driver and all fuel types</typography
            >
            <typography type="small-p" no-margin class="tooltip-limits"
              >No restriction on daily and monthly limit</typography
            >
            <typography type="small-p" no-margin class="tooltip-note"
              >All in-store and product purchases enabled</typography
            >
          </div>
        </tooltip>
        setting. You can order any number of cards after selecting the "Submit"
        button at the end of the form.
      </typography>
    </div>
  </box>
</template>

<script>
import Box from '@/components/atoms/Box.vue';
import Typography from '@/components/atoms/Typography.vue';
import ZCard from '@/components/molecules/ZCard.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';
import { BRAND } from '@/constants/form';

export default {
  components: {
    Box,
    Typography,
    ZCard,
    Tooltip
  },
  computed: {
    brand: () => BRAND
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.info-box.box {
  padding: rem(25px);
  display: inline-flex;
  align-items: center;
}

.card-box {
  margin-right: rem(40px);
}

.text {
  color: $charcoal;
  max-width: 100%;

  @include md {
    width: rem(220px);
  }
}

.tooltip-content {
  color: $charcoal;
  text-align: center;
}

.tooltip-note {
  color: $note-color;
}
</style>
