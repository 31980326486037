<template>
  <form-section :id="sectionId" class="your-business" @visibility="onChangeSectionVisibility">
    <form-group :is-visible="currentQuestion === businessTypeId" @visibility="isVisible => onChangeQuestionVisibility(isVisible, businessTypeId)
      ">
      <typography v-if="$vuetify.breakpoint.smAndUp" type="tag">{{ brand }} application</typography>
      <mobile-full-page-wrapper :go-to-id="businessTypeId" show-arrow>
        <typography type="h3">
          Nice meeting you {{ firstName }},
          <br />let’s learn a bit about your
          business.
        </typography>
      </mobile-full-page-wrapper>

      <mobile-full-page-wrapper :id="businessTypeId">
        <v-layout :mt-5="$vuetify.breakpoint.smAndUp" :justify-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs8>
            <typography type="h5">How have you set up your business?</typography>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex>
            <toggle-button-wrapper>
              <toggle-button :active="businessType === limitedCompanyValue" :value="limitedCompanyValue"
                label="Limited company" name="LimitedCompanyButton" has-suboption @click="onBusinessTypeChange">
                <limited-company-icon />
              </toggle-button>
              <toggle-button :active="businessType === soleTraderValue" :value="soleTraderValue" label="Sole trader"
                name="SoleTraderButton" has-suboption @click="onBusinessTypeChange">
                <sole-trader-icon />
              </toggle-button>
              <toggle-button :active="businessType === partnershipValue" :value="partnershipValue" label="Partnership"
                name="PartnershipButton" has-suboption @click="onBusinessTypeChange">
                <partnership-icon />
              </toggle-button>
              <toggle-button :active="businessType === trustOrSocietyValue" :value="trustOrSocietyValue"
                label="Charity, Trust or Society" name="TrustOrSocietyButton" has-suboption
                @click="onBusinessTypeChange">
                <trust-icon />
              </toggle-button>
              <toggle-button :active="businessType === otherValue" :value="otherValue" label="Other"
                name="OtherBusinessTypeButton" has-suboption @click="onBusinessTypeChange">
                <other-icon />
              </toggle-button>
            </toggle-button-wrapper>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
      <!-- Business use only  -->
      <mobile-full-page-wrapper v-if="businessType !== ''" :id="businessUseOnlyId">
        <div>
          <typography type="h5">Is your {{ brand }} account for business or charity use?</typography>
          <v-layout justify-center>
            <v-flex sm12>
              <toggle-button-wrapper>
                <toggle-button :active="cardUse === cardUseYes" :value="cardUseYes" label="Yes"
                  name="BusinessCardUseButton" @click="onCardUseChange" />
                <toggle-button :active="cardUse === cardUseNo" :value="cardUseNo" label="No"
                  name="PersonalCardUseButton" @click="onCardUseChange" />
              </toggle-button-wrapper>
            </v-flex>
          </v-layout>
          <v-layout justify-center>
            <v-flex sm12>
              <box>
                <checkbox v-model="fuelCardBusinessUseOnlyDeclare" name="fuelCardBusinessUseOnlyDeclare"
                  label="I declare that I will use the Z Business account wholly or predominantly for business purposes.  I will not use the Z Business account wholly or predominantly for personal, domestic, or household purposes." />
                <checkbox v-model="fuelCardBusinessUseOnlyConfirm" name="fuelCardBusinessUseOnlyConfirm"
                  label="I have read and understood the declaration made above." />
              </box>
            </v-flex>
          </v-layout>
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mt-5>
              <v-flex xs10 sm6>
                <round-button name="confirmCardForBusinessUseButton" v-if="!businessTypeComplete"
                  :disabled="!(cardUse && fuelCardBusinessUseOnlyDeclare && fuelCardBusinessUseOnlyConfirm)" outline
                  @click="onChangeBusinessUseOnly">Next</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </div>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Business name + trading name -->
    <form-group v-if="businessTypeComplete" :is-visible="currentQuestion === businessNameId" @visibility="isVisible => onChangeQuestionVisibility(isVisible, businessNameId)
      ">
      <mobile-full-page-wrapper :id="businessNameId">
        <v-layout :justify-space-between="$vuetify.breakpoint.xsOnly" :justify-center="$vuetify.breakpoint.smOnly"
          fill-height column>
          <!-- Business name title -->
          <v-flex class="no-grow" mb-1>
            <v-layout justify-center>
              <v-flex xs10 sm12>
                <typography type="h5">{{ whatIsTheNameOfText }}</typography>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Business name field -->
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm8 lg7>
                <business-autocomplete v-if="!hasManualInputBusiness" v-model="business"
                  :loading="isSearchingBusinessName || (business && !hasCompletedDetailsLookup)"
                  :items="businessLookupResponse" :text-value="businessName" :rules="[
                    () => $v.business.required || 'This field is required'
                  ]" item-text="entityName" item-value="entityName" :label="whatIsTheNameOfTextInput"
                  name="BusinessNameSearch" :no-data-text="`Search for ${whatIsTheNameOfNoDataPronoun}`" :trouble-text="businessType !== limitedCompanyValue
                    ? `Having trouble finding ${whatIsTheNameOfNoDataPronoun}?`
                    : null
                    " no-margin @text-input="onChangeBusinessName" @search="onBusinessNameSearch"
                  @manual="onUseManualInputBusiness" @blur="$v.business.$touch()" />
                <text-input v-if="hasManualInputBusiness" v-model="businessName" :rules="[
                  () => $v.businessName.required || 'This field is required',
                  () => $v.businessName.maxLength || 'This field is too long'
                ]" :label="whatIsTheNameOfTextInput" name="BusinessName" no-margin @blur="$v.businessName.$touch()"
                  :maxlength="150" />


                <checkbox v-if="businessType !== otherValue" v-model="hasDifferentTradingName"
                  :label="`My trading name is different to my registered ${whatIsTheNameOfNoData}`"
                  name="BusinessHasDifferentTradingName" />
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- Trading name -->
          <v-flex v-if="businessType !== otherValue && hasDifferentTradingName" :id="tradingNameId" class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm8 lg7 mt-5>
                <text-input v-model="tradingName" :rules="[
                  () => $v.tradingName.required || 'This field is required'
                ]" label="Trading name" name="BusinessTradingName" @blur="$v.tradingName.$touch()" />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="business && business.address" mt-4>
            <h4 class="bold">
              Your registered business address is: {{
                business.address.fullAddress }}
            </h4>
            <p class="mt-2">
              If this address is wrong, please contact the relevant Register.
              <tooltip :top="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.xsOnly">

                <icon-button slot="activator">
                  <help-icon />
                </icon-button>

                <div class="address-help">
                  <typography type="small-p" no-margin>
                    This could be the Companies Register, NZBN Register or another Register.
                  </typography>
                </div>
              </tooltip>
              <br>
              You can select an alternate card delivery address later in this application.
            </p>
          </v-flex>
          <!-- Business name complete button -->
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs9 sm6 md12 mt-4>
                <round-button v-if="!businessNameComplete"
                  :disabled="$v.$invalid || (!hasManualInputBusiness && !hasCompletedDetailsLookup)" outline
                  @click="onBusinessNameCompleteClick">Next</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Physical address -->
    <form-group
      v-if="businessNameComplete && fuelCardBusinessUseOnlyDeclare && fuelCardBusinessUseOnlyConfirm && !(business && business.address)"
      :is-visible="currentQuestion === physicalAddressId" @visibility="isVisible => onChangeQuestionVisibility(isVisible, physicalAddressId)
        ">
      <mobile-full-page-wrapper :id="physicalAddressId">
        <v-layout :justify-space-between="$vuetify.breakpoint.xsOnly" :justify-center="$vuetify.breakpoint.smOnly"
          fill-height column>
          <!-- Physical address title -->
          <v-flex class="no-grow">
            <v-layout justify-center>
              <v-flex xs10 sm12>
                <typography type="h5">
                  What is {{ tradingNameWithBusinessNameFallback }}'s physical
                  address?
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- Physical address field -->
          <v-flex class="no-grow" mt-1>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm8 lg7>
                <autocomplete v-if="!hasManualPhysicalAddress" v-model="physicalAddress"
                  :loading="isSearchingPhysicalAddress" :items="physicalAddressLookupResponse"
                  :text-value="physicalAddressText" :rules="[
                    () =>
                      $v.physicalAddress.required || 'This field is required'
                  ]" item-text="fullAddress" item-value="fullAddress" label="Physical address"
                  name="BusinessAddressSearch" no-data-text="Search for your address"
                  trouble-text="Having trouble finding your address?" no-margin
                  @text-input="onChangePhysicalAddressText" @search="onPhysicalAddressSearch"
                  @manual="onUseManualPhysicalAddress" @blur="$v.physicalAddress.$touch()"
                  @input="onPhysicalAddressChange" />
                <text-input v-if="hasManualPhysicalAddress" v-model="physicalAddressText" :rules="[
                  () =>
                    $v.physicalAddressText.required ||
                    'This field is required'
                ]" label="Physical address" name="BusinessAddress" no-margin @blur="$v.physicalAddressText.$touch()" />
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- Physical address next button -->
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs9 sm6 md12 mt-5>
                <round-button v-if="!physicalAddressComplete" :disabled="$v.$invalid" outline
                  @click="onPhysicalAddressCompleteClick">Next</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Fuel amount -->
    <form-group
      v-if="(physicalAddressComplete || (business && business.address && businessNameComplete)) && fuelCardBusinessUseOnlyDeclare && fuelCardBusinessUseOnlyConfirm"
      :id="fuelAmountId" :is-visible="currentQuestion === fuelAmountId" @visibility="isVisible => onChangeQuestionVisibility(isVisible, fuelAmountId)
        ">
      <mobile-full-page-wrapper>
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly" align-center>
          <v-flex md9>
            <typography type="h5">
              How much does {{ tradingNameWithBusinessNameFallback }} roughly
              spend on fuel each month?
            </typography>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 md10>
                <typography :type="$vuetify.breakpoint.xsOnly
                  ? 'small-p'
                  : 'small-caption-text'
                  " light>
                  This doesn't need to be exact. You can type in a figure
                  below, use the slider, or select a range from 1 to 20+
                  vehicles.
                </typography>
              </v-flex>
            </v-layout>
            <fuel-slider :maxValue="specialRatesThreshold" />
            <v-layout justify-center>
              <v-flex xs10 sm6 md12>
                <round-button :disabled="$v.$invalid" @click="onNextSectionClick">{{ businessSectionComplete ? 'Confirm'
                  : 'Next: Your offer' }}</round-button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>
  </form-section>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import ToggleButtonWrapper from '@/components/atoms/ToggleButtonWrapper.vue';
import ToggleButton from '@/components/molecules/ToggleButton.vue';
import FormGroup from '@/components/atoms/FormGroup.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import Box from '@/components/atoms/Box.vue';
import FuelSlider from '@/components/organisms/FuelSlider.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import Autocomplete from '@/components/molecules/Autocomplete.vue';
import BusinessAutocomplete from '@/components/molecules/BusinessAutocomplete.vue';
import LimitedCompanyIcon from '@/assets/icons/limited-company.svg';
import SoleTraderIcon from '@/assets/icons/sole-trader.svg';
import PartnershipIcon from '@/assets/icons/partnership.svg';
import TrustIcon from '@/assets/icons/trust.svg';

import Tooltip from '@/components/atoms/Tooltip.vue';
import OtherIcon from '@/assets/icons/other.svg';
import { mapGetters, mapState } from 'vuex';
import {
  BUSINESS_TYPE,
  BUSINESS_NAME,
  BUSINESS_USE_ONLY,
  TRADING_NAME,
  PHYSICAL_ADDRESS,
  FUEL_AMOUNT,
  SECTION_YOUR_BUSINESS,
  OFFER_BANNER
} from '@/constants/html-ids';

import HelpIcon from '@/assets/icons/help.svg';
import {
  LIMITED_COMPANY,
  SOLE_TRADER,
  PARTNERSHIP,
  TRUST_OR_SOCIETY,
  BUSINESS_TYPE_OTHER,
  SPECIAL_RATES_THRESHOLD,
  BRAND
} from '@/constants/form';
import { SPECIAL_RATES } from '@/constants/routes';
import { requiredIf, maxLength, sameAs, } from 'vuelidate/lib/validators';
import { navigationOffset } from '@/helpers/navigation';
import IconButton from '../atoms/IconButton.vue';

export default {
  components: {
    Typography,
    Autocomplete,
    BusinessAutocomplete,
    FormSection,
    ToggleButton,
    ToggleButtonWrapper,
    FormGroup,
    Checkbox,
    Box,
    RoundButton,
    FuelSlider,
    TextInput,
    MobileFullPageWrapper,
    LimitedCompanyIcon,
    SoleTraderIcon,
    PartnershipIcon,
    TrustIcon,
    Tooltip,
    IconButton,
    HelpIcon,
    OtherIcon
  },
  data() {
    return {
      specialRatesThreshold: SPECIAL_RATES_THRESHOLD
    };
  },
  computed: {
    business: {
      get() {
        return this.$store.state.business.business;
      },
      set(value) {
        this.$store.dispatch('business/changeBusiness', value);
      }
    },
    fuelCardBusinessUseOnlyDeclare: {
      get() {
        return this.$store.state.business.fuelCardBusinessUseOnlyDeclare;
      },
      set(value) {
        this.$store.dispatch(
          'business/changeFuelCardBusinessUseOnlyDeclare',
          value
        );
      }
    },
    fuelCardBusinessUseOnlyConfirm: {
      get() {
        return this.$store.state.business.fuelCardBusinessUseOnlyConfirm;
      },
      set(value) {
        this.$store.dispatch(
          'business/changeFuelCardBusinessUseOnlyConfirm',
          value
        );
      }
    },
    businessName: {
      get() {
        return this.$store.state.business.businessName;
      },
      set(value) {
        this.$store.dispatch('business/changeBusinessName', value);
      }
    },
    physicalAddress: {
      get() {
        return this.$store.state.business.physicalAddress;
      },
      set(value) {
        this.$store.dispatch('business/changePhysicalAddress', value);
      }
    },
    physicalAddressText: {
      get() {
        return this.$store.state.business.physicalAddressText;
      },
      set(value) {
        this.$store.dispatch('business/changePhysicalAddressText', value);
      }
    },
    tradingName: {
      get() {
        return this.$store.state.business.tradingName;
      },
      set(value) {
        this.$store.dispatch('business/changeTradingName', value);
      }
    },
    hasDifferentTradingName: {
      get() {
        return this.$store.state.business.hasDifferentTradingName;
      },
      set(value) {
        this.$store.dispatch('business/changeHasDifferentTradingName', value);
      }
    },
    ...mapState({
      businessType: state => state.business.businessType,
      cardUse: state => state.business.fuelCardBusinessUseOnly,
      businessLookupResponse: state => state.business.businessLookupResponse,
      businessNameComplete: state => state.business.businessNameComplete,
      physicalAddressComplete: state => state.business.physicalAddressComplete,
      physicalAddressLookupResponse: state => state.business.physicalAddressLookupResponse,
      hasManualInputBusiness: state => state.business.hasManualInputBusiness,
      hasManualPhysicalAddress: state => state.business.hasManualPhysicalAddress,
      isSearchingBusinessName: state => state.progress.businessNameLookup.inProgress,
      isSearchingPhysicalAddress: state => state.progress.physicalAddressLookup.inProgress,
      fuelAmount: state => state.business.fuelAmount,
      businessSectionComplete: state => state.business.sectionComplete,
      hasCompletedDetailsLookup: state => state.business.hasCompletedDetailsLookup
    }),
    ...mapGetters({
      currentQuestion: 'navigation/currentQuestion',
      firstName: 'landing/firstName',
      businessTypeComplete: 'business/businessTypeComplete',
      tradingNameWithBusinessNameFallback: 'business/tradingNameWithBusinessNameFallback'
    }),
    businessTypeId: () => BUSINESS_TYPE,
    businessNameId: () => BUSINESS_NAME,
    businessUseOnlyId: () => BUSINESS_USE_ONLY,
    tradingNameId: () => TRADING_NAME,
    physicalAddressId: () => PHYSICAL_ADDRESS,
    fuelAmountId: () => FUEL_AMOUNT,
    sectionId: () => SECTION_YOUR_BUSINESS,
    limitedCompanyValue: () => LIMITED_COMPANY,
    soleTraderValue: () => SOLE_TRADER,
    partnershipValue: () => PARTNERSHIP,
    trustOrSocietyValue: () => TRUST_OR_SOCIETY,
    otherValue: () => BUSINESS_TYPE_OTHER,
    brand: () => BRAND,
    cardUseNo: () => false,
    cardUseYes: () => true,
    whatIsTheNameOfText: {
      get() {
        if (this.$store.state.business.businessType === SOLE_TRADER) {
          return 'What name have you registered as a sole trader?';
        } else if (this.$store.state.business.businessType === PARTNERSHIP) {
          return 'What is the name of your partnership?';
        } else if (this.$store.state.business.businessType === TRUST_OR_SOCIETY) {
          return 'What is the name of your charity, trust or society?';
        } else {
          return 'What is the name of your business?';
        }
      }
    },
    whatIsTheNameOfTextInput: {
      get() {
        const businessType = this.$store.state.business.businessType;
        if (businessType === SOLE_TRADER) {
          return 'Sole trader name';
        } else if (businessType === PARTNERSHIP) {
          return 'Partnership name';
        } else if (businessType === TRUST_OR_SOCIETY) {
          return 'Charity, trust or society name';
        } else {
          return 'Business name';
        }
      }
    },
    whatIsTheNameOfNoData: {
      get() {
        const businessType = this.$store.state.business.businessType;
        if (businessType === SOLE_TRADER) {
          return 'sole trader';
        } else if (businessType === PARTNERSHIP) {
          return 'partnership';
        } else if (businessType === TRUST_OR_SOCIETY) {
          return 'charity, trust or society';
        } else {
          return 'business';
        }
      }
    },
    whatIsTheNameOfNoDataPronoun: {
      get() {
        const businessType = this.$store.state.business.businessType;
        if (businessType === SOLE_TRADER) {
          return `the ${this.whatIsTheNameOfNoData}`;
        } else if (businessType === PARTNERSHIP) {
          return `your ${this.whatIsTheNameOfNoData}`;
        } else if (businessType === TRUST_OR_SOCIETY) {
          return `your ${this.whatIsTheNameOfNoData}`;
        } else {
          return `your ${this.whatIsTheNameOfNoData}`;
        }
      }
    }
  },
  methods: {
    async onBusinessTypeChange(value) {
      this.$store.dispatch('business/changeBusinessType', value);

      this.$store.dispatch('business/changeBusinessUseOnly', false);
      this.$store.dispatch('business/changeFuelCardBusinessUseOnlyDeclare', false);
      this.$store.dispatch('business/changeFuelCardBusinessUseOnlyConfirm', false);
      this.$store.dispatch('business/changeBusinessTypeComplete', false);
      await this.$nextTick();

      // AO-628: Enforce the use of the company lookup for limited companies
      if (value === LIMITED_COMPANY) {
        this.$store.dispatch('business/changeHasManualInputBusiness', false);
      }

      await this.$nextTick();

      this.$vuetify.goTo(`#${BUSINESS_USE_ONLY}`, {
        offset: navigationOffset(BUSINESS_USE_ONLY)
      });

    },
    async onCardUseChange(value) {
      if (value === this.cardUseNo) {
        this.$store.dispatch('ui/togglePersonalCardDialogOpen', true);
        return;
      }

      this.$store.dispatch('business/changeFuelCardBusinessUseOnly', value);

      await this.$nextTick();
    },
    async onChangeBusinessUseOnly() {
      this.$store.dispatch('business/changeBusinessUseOnly', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${BUSINESS_NAME}`, {
        offset: navigationOffset(BUSINESS_NAME)
      });
    },
    onChangeBusinessName(value) {
      this.$store.dispatch('business/changeBusinessName', value);
    },
    onBusinessNameSearch() {
      this.$store.dispatch('business/businessNameLookup');
    },
    onUseManualInputBusiness() {
      this.$store.dispatch('business/changeHasManualInputBusiness', true);
    },
    onChangePhysicalAddressText(value) {
      this.$store.dispatch('business/changePhysicalAddressText', value);
    },
    onPhysicalAddressSearch() {
      this.$store.dispatch('business/physicalAddressLookup');
    },
    async onPhysicalAddressChange(value,) {
      await this.$nextTick();
      if (value.dpid) {
        this.$store.dispatch('business/prePopulateDeliveryAddress', value.dpid);
      }
    },
    onUseManualPhysicalAddress() {
      this.$store.dispatch('business/changeHasManualPhysicalAddress', true);
    },
    async onBusinessNameCompleteClick() {
      this.$store.dispatch('business/changeBusinessNameComplete', true);

      await this.$nextTick();

      if (this.business?.address) {
        this.$vuetify.goTo(`#${FUEL_AMOUNT}`, {
          offset: navigationOffset(FUEL_AMOUNT)
        });
      } else {

        this.$vuetify.goTo(`#${PHYSICAL_ADDRESS}`, {
          offset: navigationOffset(PHYSICAL_ADDRESS)
        });
      }
    },
    async onPhysicalAddressCompleteClick() {
      this.$store.dispatch('business/changePhysicalAddressComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${FUEL_AMOUNT}`, {
        offset: navigationOffset(FUEL_AMOUNT)
      });
    },
    async onNextSectionClick() {
      this.$store.dispatch('offer/changeStandardOffer', {
        fuelAmount: this.fuelAmount
      });
      this.$store.dispatch('business/changeSectionComplete', true);

      if (this.fuelAmount >= SPECIAL_RATES_THRESHOLD) {
        this.$router.push(SPECIAL_RATES);
      } else {
        await this.$nextTick();

        this.$vuetify.goTo(`#${OFFER_BANNER}`, {
          offset: navigationOffset(OFFER_BANNER)
        });
      }
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch(
          'navigation/addSectionInView',
          SECTION_YOUR_BUSINESS
        );
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_YOUR_BUSINESS
        );
      }
    },
    onChangeQuestionVisibility(isVisible, htmlId) {
      if (isVisible) {
        this.$store.dispatch('navigation/addQuestionInView', htmlId);
      } else {
        this.$store.dispatch('navigation/removeQuestionInView', htmlId);
      }
    }
  },
  validations: {
    business: {
      required: requiredIf(
        nestedModel =>
          nestedModel.businessTypeComplete &&
          !nestedModel.hasManualInputBusiness
      )
    },
    fuelCardBusinessUseOnlyDeclare: {
      sameAs: sameAs(nestedModel => {
        if (nestedModel.currentQuestion !== nestedModel.submitFormId) {
          // If not up to the section, can be the current value
          return nestedModel.fuelCardBusinessUseOnlyDeclare;
        }

        // Else should be checked
        return true;
      })
    },
    fuelCardBusinessUseOnlyConfirm: {
      sameAs: sameAs(nestedModel => {
        if (nestedModel.currentQuestion !== nestedModel.submitFormId) {
          // If not up to the section, can be the current value
          return nestedModel.fuelCardBusinessUseOnlyConfirm;
        }

        // Else should be checked
        return true;
      })
    },
    businessName: {
      required: requiredIf(
        nestedModel =>
          nestedModel.businessTypeComplete && nestedModel.hasManualInputBusiness
      ),
      maxLength: maxLength(150)
    },
    tradingName: {
      required: requiredIf(
        nestedModel =>
          nestedModel.businessTypeComplete &&
          nestedModel.hasDifferentTradingName
      )
    },
    physicalAddress: {
      required: requiredIf(
        nestedModel =>
          nestedModel.businessNameComplete &&
          !nestedModel.hasManualPhysicalAddress &&
          !nestedModel.business?.address
      )
    },
    physicalAddressText: {
      required: requiredIf(
        nestedModel =>
          nestedModel.businessNameComplete &&
          nestedModel.hasManualPhysicalAddress
      )
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

div[name="TrustOrSocietyButton"] {
  padding-bottom: 5px;
}

.address-help {
  max-width: 100%;
  width: rem(440px);
  color: $charcoal;
}
</style>
