<template>
  <button
    :class="['text-note-button', `text-note-button--${align}`]"
    type="button"
    :name="name"
    @click="onClick"
  >
    <typography class="text-note-button__label" type="h4">{{
      label
    }}</typography>
    <typography
      v-if="note"
      class="text-note-button__note"
      type="small-caption-text"
      no-margin
      >{{ note }}</typography
    >
  </button>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    note: {
      type: String,
      default: ''
    },
    value: {
      type: null,
      default: ''
    },
    align: {
      type: String,
      default: '',
      validator: value => {
        return ['left', 'center', 'right', ''].includes(value);
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('click', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.text-note-button {
  outline: 0;

  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
  &--center {
    text-align: center;
  }
  &__label {
    margin-bottom: 0;
  }

  &:hover {
    .text-note-button__label {
      text-decoration: underline;
    }
  }
}
</style>
