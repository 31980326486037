<template>
  <editor-content :editor="editor" />
</template>

<script>
import { Editor, EditorContent } from 'tiptap';

import {
  BulletList,
  Heading,
  ListItem,
  Bold,
  Italic,
  History
} from 'tiptap-extensions';

import {
  BlueBachColorMark,
  IndigoColorMark,
  OrangeColorMark
} from '@/plugins/tiptap-color-plugin';

export default {
  components: {
    EditorContent
  },
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      editor: new Editor({
        editable: false,
        extensions: [
          new BlueBachColorMark(),
          new IndigoColorMark(),
          new OrangeColorMark(),
          new BulletList(),
          new Heading({ maxLevel: 1 }),
          new ListItem(),
          new Bold(),
          new Italic(),
          new History()
        ]
      })
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.editor.setContent(JSON.parse(value));
        } else {
          this.editor.clearContent();
        }
      }
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
};
</script>
