<template>
  <div class="requirements-box">
    <v-layout :justify-center="$vuetify.breakpoint.mdAndUp">
      <v-flex xs8 sm10>
        <typography
          :type="$vuetify.breakpoint.xsOnly ? 'small-p' : 'body'"
          bold
        >
          A few specific details we'll be asking, is their:
        </typography>
      </v-flex>
    </v-layout>
    <ul class="requirements">
      <li class="requirement">
        <typography
          :type="$vuetify.breakpoint.xsOnly ? 'small-p' : 'body'"
          no-margin
        >
          Legal name
        </typography>
      </li>
      <li class="requirement">
        <typography
          :type="$vuetify.breakpoint.xsOnly ? 'small-p' : 'body'"
          no-margin
        >
          Date of Birth
        </typography>
      </li>
      <li class="requirement">
        <typography
          :type="$vuetify.breakpoint.xsOnly ? 'small-p' : 'body'"
          no-margin
        >
          Driver licence
        </typography>
      </li>
      <li class="requirement">
        <typography
          :type="$vuetify.breakpoint.xsOnly ? 'small-p' : 'body'"
          no-margin
        >
          Residential address
        </typography>
      </li>
    </ul>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.requirements-box {
  width: 100%;
  text-align: left;
  background-color: #f8f8f8;
  border-radius: rem(10px);
  padding: rem(15px 20px);

  @include sm {
    padding: rem(30px 20px);
  }

  @include md {
    text-align: center;
    padding: rem(40px);
  }
}

.requirements {
  padding-left: rem(15px);

  @include md {
    padding-left: rem(20);
  }
}

.requirement {
  position: relative;
  list-style-type: none;

  @include md {
    display: inline-block;
    margin: rem(0 20px);
  }

  @include lg {
    margin: rem(0 35px);
  }

  &:before {
    content: '\2022';
    position: absolute;
    top: 50%;
    color: $orange;
    left: rem(-15px);
    transform: translate(0, -50%);
  }
}
</style>
