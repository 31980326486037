<template>
  <v-slider
    :value="value"
    :tick-size="tickSize"
    :min="min"
    :max="max"
    :step="step"
    thumb-color="white"
    class="slider"
    @input="onInput"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    tickSize: {
      type: Number,
      default: 1
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    // Fixes an issue where dragging the slider "thumb" and moving the
    // mouse cursor outside of the browser window to cause the slider
    // thumb to move without having the mouse button held down until
    // clicking the mouse button again
    fixSlider(ev) {
      // Check if the user was holding down a mouse button/buttons when they left the screen
      if (ev.buttons > 0) {
        // Unfortunately there isn't a better way to target the slider "thumb"
        // than using `.v-slider__thumb` class without modifying the underlying
        // vuetify slider component
        this.$el.querySelectorAll('.v-slider__thumb').forEach(el => {
          const evt = document.createEvent('MouseEvents');
          evt.initEvent('mouseup', true, true);
          el.dispatchEvent(evt);
        });
      }
    }
  },
  mounted: function() {
    document.body.addEventListener('mouseleave', this.fixSlider);
  },
  destroyed: function() {
    document.body.removeEventListener('mouseleave', this.fixSlider);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.slider {
  /deep/ .v-slider {
    input {
      cursor: pointer;
    }

    &__track,
    &__track-fill,
    &__track__container {
      border-radius: rem(5px);
      height: rem(8px);

      @include md {
        height: rem(4px);
      }
    }

    &__track {
      background-color: $light-charcoal;
    }

    &__track-fill {
      background-color: $mid-orange !important;
    }

    &__thumb {
      cursor: pointer;
      box-shadow: 0 0 20px 0 #e7e7e7, inset 0 0 5px 0 rgba(241, 241, 241, 0.5);
      transform: translateY(-50%) scale(1);
      width: rem(35px);
      height: rem(35px);
      left: rem(-16px);
      border: 0 !important;
      background-color: $white;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        width: rem(2px);
        height: rem(12px);
        background-color: #e4e4e4;
        transform: translate(0, -50%);
      }

      &:after {
        right: 37%;
      }

      &:before {
        left: 37%;
      }
    }
  }
}
</style>
