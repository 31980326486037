<template>
  <div class="fuel-slider">
    <slider-input-summary
      v-model="fuelAmount"
      name="MonthlyFuelAmount"
      prefix="$"
      text="per month"
    />
    <slider
      v-model="fuelAmountSlider"
      :min="0"
      :max="maxValue"
      :step="1"
      class="fuel-slider__slider"
    />
    <div class="fuel-slider__buttons">
      <text-and-note-button
        :value="lowRate"
        align="left"
        :label="lowLabel"
        name="LowFuelAmountLink"
        @click="onButtonClick"
      />
      <text-and-note-button
        :value="mediumRate"
        align="center"
        :label="mediumLabel"
        name="MediumFuelAmountLink"
        @click="onButtonClick"
      />
      <text-and-note-button
        :value="maxValue"
        align="right"
        :label="highLabel"
        name="HighFuelAmountLink"
        @click="onButtonClick"
      />
    </div>
  </div>
</template>

<script>
import Slider from '@/components/atoms/Slider.vue';
import TextAndNoteButton from '@/components/molecules/TextAndNoteButton.vue';
import SliderInputSummary from '@/components/molecules/SliderInputSummary.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Slider,
    TextAndNoteButton,
    SliderInputSummary
  },
  props: {
    lowLabel: {
      type: String,
      default: '1 - 4 vehicles'
    },
    mediumLabel: {
      type: String,
      default: '5 - 19 vehicles'
    },
    highLabel: {
      type: String,
      default: '20+ vehicles'
    },
    maxValue: {
      type: Number
    }
  },
  computed: {
    lowRate() {
      return Math.floor(this.maxValue / 16);
    },
    mediumRate() {
      return Math.floor(this.maxValue / 2);
    },
    fuelAmount: {
      get() {
        return this.$store.state.business.fuelAmount;
      },
      set(value) {
        this.$store.dispatch('business/changeFuelAmount', value);
      }
    },
    fuelAmountSlider: {
      get() {
        return this.fuelAmount >= this.maxValue
          ? this.maxValue
          : this.fuelAmount;
      },
      set(value) {
        this.$store.dispatch('business/changeFuelAmount', value);
      }
    },
    ...mapGetters({
      formattedFuelAmount: 'business/formattedFuelAmount'
    })
  },
  methods: {
    onButtonClick(value) {
      this.$store.dispatch('business/changeFuelAmount', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.fuel-slider {
  width: calc(100% + #{rem($container-mobile-padding * 2)});
  margin: rem(
    15px -#{$container-mobile-padding} 40px -#{$container-mobile-padding}
  );
  background-color: #f8f8f8;
  text-align: center;
  padding: rem(45px 30px);
  @include sm {
    max-width: 100%;
    margin: rem(15px 0 80px 0);
    border-radius: rem(20px);
    padding: rem(45px 60px);
  }
  @include md {
    padding: rem(45px 90px);
  }
  &__summary-text {
    margin-bottom: 0;
  }
  &__slider {
    @include sm {
      margin-bottom: rem(30px);
    }
  }
  &__amount {
    margin-right: rem(6px);
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    color: $mid-orange;
  }
}
.fuel-slider /deep/ .v-slider__track {
  background-color: #efefef;
}
</style>
