<template>
  <section :class="['form-section', { 'form-section--no-margin': noMargin }]">
    <div
      v-observe-visibility="onVisibilityChanged"
      class="section-visibility"
    />
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    noMargin: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onVisibilityChanged(isVisible, entry) {
      this.$emit('visibility', isVisible, entry);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.form-section {
  position: relative;
  width: 100%;
  margin-bottom: 30vh;

  &--no-margin {
    margin-bottom: 0;
  }
}

.section-visibility {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  background-color: red;
}
</style>
