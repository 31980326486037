import { Mark } from 'tiptap';
import { toggleMark } from 'tiptap-commands';
const COLORS = {
  INDIGO: '#54388c',
  BACH_BLUE: '#00b3e6',
  ORANGE: '#ff6f2a'
};

export class BlueBachColorMark extends Mark {
  get name() {
    return 'bluebachcolor';
  }

  get schema() {
    return {
      attrs: {
        style: { default: `color: ${COLORS.BACH_BLUE}` }
      },
      excludes: 'indigocolor bluebachcolor orangecolor',
      parseDOM: [
        {
          style: `color=${COLORS.BACH_BLUE}`,
          attrs: { style: `color: ${COLORS.BACH_BLUE}` }
        }
      ],
      toDOM(node) {
        return ['span', { style: node.attrs.style }, 0];
      }
    };
  }

  commands({ type }) {
    return () => toggleMark(type);
  }
}

export class IndigoColorMark extends Mark {
  get name() {
    return 'indigocolor';
  }

  get schema() {
    return {
      attrs: {
        style: { default: `color: ${COLORS.INDIGO};` }
      },
      excludes: 'indigocolor bluebachcolor orangecolor',
      parseDOM: [
        {
          style: `color=${COLORS.INDIGO}`,
          attrs: { style: `color: ${COLORS.INDIGO}` }
        }
      ],
      toDOM(node) {
        return ['span', { style: node.attrs.style }, 0];
      }
    };
  }

  commands({ type }) {
    return () => toggleMark(type);
  }
}

export class OrangeColorMark extends Mark {
  get name() {
    return 'orangecolor';
  }

  get schema() {
    return {
      attrs: {
        style: { default: `color: ${COLORS.ORANGE};` }
      },
      excludes: 'indigocolor bluebachcolor orangecolor',
      parseDOM: [
        {
          style: `color=${COLORS.ORANGE}`,
          attrs: { style: `color: ${COLORS.ORANGE}` }
        }
      ],
      toDOM(node) {
        return ['span', { style: node.attrs.style }, 0];
      }
    };
  }

  commands({ type }) {
    return () => toggleMark(type);
  }
}
