import { render, staticRenderFns } from "./DatePicker.vue?vue&type=template&id=2bc961ea&scoped=true&"
import script from "./DatePicker.vue?vue&type=script&lang=js&"
export * from "./DatePicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc961ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/runner/work/business-cards/business-cards/ola/Z.Aspiring.Ola/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib'
import { VMenu } from 'vuetify/lib'
installComponents(component, {VDatePicker,VMenu})
