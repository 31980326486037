<template>
  <header class="credit-header" v-if="!skipCreditCheck">
    <v-layout>
      <v-flex md10 lg8>
        <typography type="h3">
          Thanks, {{ firstName }}. Now we need to do a
          <tooltip top>
            <a
              slot="activator"
              class="credit-check-tooltip-activator"
              href="#"
              @click.prevent
            >credit check</a>
            <div class="credit-check-tooltip">
              <typography type="h4">Why am I being credit checked?</typography>
              <typography type="small-p">
                We perform credit checks on both the business and the Director,
                so that we can properly assess the credit risk to us at Z. This
                allows us to make an informed decision on whether to provide a
                credit account to you and your business.
              </typography>
            </div>
          </tooltip>
          on
          <span v-if="isLimitedCompany">a <strong>director</strong></span>
          <span v-else>you</span>
          from {{ tradingNameWithBusinessNameFallback }}.
        </typography>
      </v-flex>
    </v-layout>
    <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
      <v-flex xs10>
        <typography v-if="isLimitedCompany" light>
          We’ll only need the details of <strong>one</strong> company director
          if there are multiple within your business.
        </typography>
      </v-flex>
    </v-layout>
  </header>

  <header v-else>
    <v-layout>
      <v-flex md10 lg8>
        <typography type="h3">
          Thanks, {{ firstName }}. Now we need the details
          of your authorised contact
          from {{ tradingNameWithBusinessNameFallback }}.
        </typography>
      </v-flex>
    </v-layout>
  </header>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Typography,
    Tooltip
  },
  computed: {
    ...mapGetters({
      firstName: 'landing/firstName',
      tradingNameWithBusinessNameFallback:
        'business/tradingNameWithBusinessNameFallback',
      isLimitedCompany: 'business/isLimitedCompany',
      skipCreditCheck: 'offer/skipCreditCheck'
    })
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.credit-check-tooltip {
  color: $charcoal;
  width: rem(370px);
}

.credit-check-tooltip-activator {
  white-space: nowrap;
}
</style>
