<template>
  <div class="license-help">
    <v-layout align-center justify-center wrap>
      <v-flex xs10 sm6>
        <driver-license-image class="image" />
      </v-flex>
      <v-flex xs10 sm6>
        <typography type="small-p"
          >Where to find the details on your driver licence:</typography
        >
        <typography type="small-p">
          <strong>5a</strong> Licence number <br />
          <strong>5b</strong> Card version number <br />
        </typography>
      </v-flex>
      <v-flex xs10 sm6> </v-flex>
      <v-flex xs10 sm6>
        <typography type="small-p">
          If you don’t have a driver licence please call us on <br />
          <a href="tel:0800 936 374">0800 Z ENERGY (0800 936 374)</a>
        </typography>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import DriverLicenseImage from '@/assets/icons/drivers-license.svg';
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    DriverLicenseImage,
    Typography
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.license-help {
  max-width: 100%;
  width: rem(440px);
  color: $charcoal;
}

.image {
}
</style>
