<template>
  <v-combobox
    :items="!textValue ? [] : items"
    :label="label"
    :rules="rules"
    :value="value"
    :hint="hint"
    :name="name"
    :append-icon="null"
    :search-input.sync="textValue"
    :hide-no-data="hideNoData"
    :class="['autocomplete', { 'autocomplete--no-margin': noMargin }]"
    persistent-hint
    :menu-props="{ contentClass: 'autocomplete-content' }"
    :readonly="readonly"
    :placeholder="placeholder"
    @input="onInput"
    @blur="onBlur"
  >
    <template slot="no-data">
      <v-list-tile>
        <v-list-tile-content>
          <typography no-margin class="no-data-text">
            {{
              loading ? 'Searching...' : textValue ? 'No results' : noDataText
            }}
          </typography>
        </v-list-tile-content>
      </v-list-tile>
    </template>
    <loading-circle
      v-if="loading"
      slot="append"
      :size="20"
      :width="2"
      class="loading-circle"
    />
  </v-combobox>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import LoadingCircle from '@/components/atoms/LoadingCircle.vue';

export default {
  components: {
    Typography,
    LoadingCircle
  },
  data() {
    return {
      textValue: null,
      loading: false
    };
  },
  props: {
    hideNoData: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      default: () => []
    },
    value: {
      type: null,
      default: ''
    },
    hint: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ' '
    },
    defaultValue: {
      type: Object,
      default: null
    }
  },
  watch: {
    textValue(newVal) {
      if ((newVal || '') === '') {
        this.$emit('input', null);
      }
    }
  },
  methods: {
    onInput(value) {
      if (!this.items.some(item => item == value)) {
        this.$emit('input', '');
        return;
      }

      this.$emit('input', value);
    },
    onBlur() {
      this.$emit('blur');
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.autocomplete {
  font-size: rem(20px);
  line-height: rem(30px);
  margin-bottom: rem(25px);
  color: $charcoal !important;

  &--no-margin {
    margin-bottom: 0;
  }

  .loading-circle {
    position: absolute;
    right: rem(-25px);
    top: 50%;
    width: rem(20px);
    height: rem(20px);
    transform: translate(0, -50%);
  }

  .v-messages {
    font-size: rem(15px);
    color: $mid-charcoal !important;

    &.error--text {
      color: $error-color !important;
    }
  }

  .v-input__slot {
    padding-right: rem(30px);

    &:after {
      color: currentColor;
      border-width: 1px;
    }

    &:before {
      border-color: $mid-charcoal !important;
      border-width: 1px;
    }

    &:hover {
      &:before {
        border-color: $charcoal !important;
      }
    }
  }

  &.error--text {
    color: $error-color !important;

    .v-input__slot {
      &:before {
        border-color: $error-color !important;
      }

      &:after {
        color: $error-color !important;
      }
    }
  }

  .v-label {
    color: currentColor;
    font-size: rem(20px);

    &--active {
      transform: translateY(-18px) scale(0.6);
      font-weight: $weight-medium;
    }
  }
}

.autocomplete-content {
  .no-data-tile {
    .v-list__tile {
      height: rem(60px);

      @include md {
        height: rem(40px);
      }
    }
  }

  .no-data-text {
    color: $mid-charcoal;
  }

  .v-list {
    padding: 0;

    &__tile {
      padding: rem(0 15px);
      font-size: rem(18px);
      height: rem(50px);
      color: $charcoal;

      @include md {
        height: rem(70px);
        font-size: rem(20px);
        padding: rem(0 25px);
      }

      &:hover {
        background-color: #f8f8f8;
        text-decoration: none;
      }

      &__content {
        border-bottom: 1px solid #e4e4e4;
      }
    }
  }
}
</style>
