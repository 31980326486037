var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'toggle-button',
    { 'toggle-button--active': _vm.active },
    { 'toggle-button--has-suboption': _vm.hasSuboption },
    { 'toggle-button--has-label': !!_vm.label },
    { 'toggle-button--no-icon': !_vm.$slots.default },
    { 'toggle-button--no-label': !_vm.label }
  ],on:{"click":_vm.onClick}},[_c('span',{staticClass:"button-content"},[_vm._t("default")],2),(_vm.label)?_c('span',{staticClass:"toggle-button-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }