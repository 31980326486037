<template>
  <modal
    v-if="promoOfferDialogOpen"
    v-model="promoOfferDialogOpen"
    center-buttons
  >
    <v-layout justify-left class="text-align--left">
      <v-flex xs12 sm12 md12>
        <rich-text-viewer v-if="legal" :value="legal" />
        <rich-text-viewer
          v-if="benefits && isDiscountOffPump"
          :value="benefits"
        />
      </v-flex>
    </v-layout>

    <round-button slot="left-button" @click="onOk">Ok</round-button>
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import RichTextViewer from '@/components/atoms/RichTextViewer';

export default {
  components: {
    Modal,
    Typography,
    RoundButton,
    RichTextViewer
  },
  computed: {
    promoOfferDialogOpen: {
      get() {
        return this.$store.state.offer.promoOfferDialogOpen;
      },
      set(value) {
        this.$store.dispatch('offer/togglePromoOfferDialogOpen', value);
      }
    },
    legal: function() {
      return (
        this.$store.state.offer.promoOffer &&
        this.$store.state.offer.promoOffer.legal
      );
    },
    benefits: function() {
      return (
        this.$store.state.offer.promoOffer &&
        this.$store.state.offer.promoOffer.benefits
      );
    },
    isDiscountOffPump() {
      return this.$store.state.offer.isDiscountOffPump;
    }
  },
  methods: {
    onOk() {
      this.promoOfferDialogOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
.text-align--left {
  text-align: left;
}
</style>
