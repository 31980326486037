<template>
  <form-section
    v-if="creditSectionComplete"
    :id="sectionId"
    class="final-section"
    no-margin
    @visibility="onChangeSectionVisibility"
  >
    <form-group
      :is-visible="currentQuestion === deliveryAddressId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, deliveryAddressId)
      "
    >
      <!-- section intro -->
      <mobile-full-page-wrapper :go-to-id="deliveryAddressId" show-arrow>
        <typography type="h3">
          You’re almost there, we just
          <br />need a few final details...
        </typography>
      </mobile-full-page-wrapper>

      <!-- Delivery address -->
      <mobile-full-page-wrapper :id="deliveryAddressId">
        <v-layout
          :mt-5="$vuetify.breakpoint.smAndUp"
          fill-height
          column
          justify-space-between
        >
          <!-- Delivery address title -->
          <v-flex mb-1>
            <typography type="h5">
              Which address should we deliver your {{ brand }} fuel cards to?
            </typography>
          </v-flex>
          <v-flex mb-1>
            <typography type="small-caption-text bold" style="color:#ff6f2a">
              Your card delivery address needs to be able to receive both regular post and courier deliveries, it cannot be a PO Box. You can change this address at any time by calling our team on 0800 936 374.
            </typography>
          </v-flex>
          <!-- Delivery address text fields -->
          <v-flex :mt-3="$vuetify.breakpoint.xsOnly">
            <v-layout>
              <v-flex md9 lg7>
                <autocomplete
                  v-if="!hasManualDeliveryAddress"
                  v-model="deliveryAddress"
                  :loading="deliveryAddressLookupInProgress"
                  :items="deliveryAddressLookupResponse"
                  :text-value="deliveryAddressText"
                  :rules="[
                    () =>
                      $v.deliveryAddress.required || 'This field is required'
                  ]"
                  item-text="fullAddress"
                  item-value="fullAddress"
                  label="Delivery address"
                  name="DeliveryAddressSearch"
                  no-data-text="Search for your address"
                  no-margin
                  @text-input="onChangeDeliveryAddressText"
                  @search="onDeliveryAddressSearch"
                  @manual="onChangeHasManualDeliveryAddress"
                  @blur="$v.deliveryAddress.$touch()"
                />
                <text-input
                  v-if="hasManualDeliveryAddress"
                  v-model="deliveryAddressText"
                  :rules="[
                    () =>
                      $v.deliveryAddressText.required ||
                      'This field is required'
                  ]"
                  label="Delivery address"
                  name="DeliveryAddress"
                  no-margin
                  @blur="$v.deliveryAddressText.$touch()"
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md9>
                <card-settings-info-box />
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- Delivery address next button -->
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mt-5>
              <v-flex xs10 sm6>
                <round-button
                  v-if="!deliveryAddressComplete"
                  :disabled="$v.$invalid"
                  outline
                  @click="onDeliveryAddressCompleteClick"
                  >Next</round-button
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Contact number -->
    <form-group
      v-if="deliveryAddressComplete"
      :is-visible="currentQuestion === contactNumberId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, contactNumberId)
      "
    >
      <mobile-full-page-wrapper :id="contactNumberId">
        <v-layout fill-height column justify-space-between>
          <!-- Contact number title -->
          <v-flex mb-4>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm12>
                <typography type="h5">
                  What is the best contact number for your business?
                </typography>
                <typography>
                  This will be the main contact number for your {{ brand }} account.
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Contact number text field -->
          <v-flex>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm9 md7 lg4>
                <text-input
                  v-model="contactNumber"
                  :rules="[
                    () => $v.contactNumber.required || 'This field is required',
                    () =>
                      $v.contactNumber.phone ||
                      'This Contact number is not valid'
                  ]"
                  label="Contact number"
                  name="ContactNumber"
                  type="tel"
                  :maxlength="20"
                  @blur="$v.contactNumber.$touch()"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Contact number next button -->
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mt-5>
              <v-flex xs10 sm6>
                <round-button
                  v-if="!contactNumberComplete"
                  :disabled="$v.$invalid"
                  outline
                  @click="onContactNumberCompleteClick"
                  >Next</round-button
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Invoice email -->
    <form-group
      v-if="contactNumberComplete"
      :is-visible="currentQuestion === invoiceEmailId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, invoiceEmailId)
      "
    >
      <mobile-full-page-wrapper :id="invoiceEmailId">
        <v-layout fill-height column justify-space-between>
          <!-- Invoice email title -->
          <v-flex>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm12>
                <typography type="h5"
                  >What email address should we send your {{ brand }} invoices to?</typography
                >
              </v-flex>
            </v-layout>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm12 md8 lg7>
                <typography
                  :type="
                    $vuetify.breakpoint.xsOnly
                      ? 'small-p'
                      : 'small-caption-text'
                  "
                  light
                >
                  Your invoices will include any account fees, fuels and other
                  purchases made on your {{ brand }} fuel cards.
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Invoice email text field -->
          <v-flex>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm10 md9 lg6>
                <text-input
                  v-model="invoiceEmail"
                  name="InvoiceEmail"
                  :rules="[
                    () => $v.invoiceEmail.required || 'This field is required',
                    () => $v.invoiceEmail.email || 'Email not valid'
                  ]"
                  label="Email address"
                  @blur="$v.invoiceEmail.$touch()"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Invoice email next button -->
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mt-5>
              <v-flex xs10 sm6>
                <round-button
                  v-if="!invoiceEmailComplete"
                  :disabled="$v.$invalid"
                  outline
                  @click="onInvoiceEmailCompleteClick"
                  >Next</round-button
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Invoice date -->
    <form-group
      v-if="invoiceEmailComplete"
      :is-visible="currentQuestion === invoiceDateId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, invoiceDateId)
      "
    >
      <mobile-full-page-wrapper :id="invoiceDateId">
        <!-- Invoice date title -->
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs8>
            <typography type="h5"
              >When would you like to be invoiced?</typography
            >
          </v-flex>
        </v-layout>
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs10 sm12 md8 lg7>
            <typography
              :type="
                $vuetify.breakpoint.xsOnly ? 'small-p' : 'small-caption-text'
              "
              light
            >
              PLEASE NOTE: Payment will be debited from your bank account 10 days after the invoice date you 
              select below. Please refer to
              <a
                :href="zTermsLink"
                target="_blank"
                rel="noopener noreferrer"
                name="PaymentTermsLink"
                @click.stop
                >Terms and Conditions</a
              >.
            </typography>
          </v-flex>
        </v-layout>
        <!-- Invoice date text field -->
        <v-layout justify-center>
          <v-flex>
            <toggle-button-wrapper>
              <toggle-button
                v-for="(label, value) in invoiceDateOptions"
                :key="value"
                :active="invoiceDate === value"
                :value="value"
                :label="label"
                @click="onChangeInvoiceDate"
              />
            </toggle-button-wrapper>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Payment plan  this section is currently being skipped due to the CCCFA No fees scheme for Digital SME-->
    
    <form-group
      v-if="invoiceDateComplete && !skipPaymentPlan"
      :is-visible="currentQuestion === paymentPlanId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, paymentPlanId)
      "
    >
      <mobile-full-page-wrapper :id="paymentPlanId">
        <v-layout fill-height column justify-space-between>
          <v-flex>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm12>
                <typography type="h5"
                  >What account fee option would you like?</typography
                >
              </v-flex>
            </v-layout>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm12 md8 lg7>
                <typography
                  :type="
                    $vuetify.breakpoint.xsOnly
                      ? 'small-p'
                      : 'small-caption-text'
                  "
                  light
                >
                  You will save $10/year by choosing to pay annually.
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <payment-plan-wrapper>
              <payment-plan
                :active="paymentPlan === paymentMonthlyValue"
                :title="
                  $vuetify.breakpoint.xsOnly ? 'Pay monthly' : 'Monthly fee'
                "
                name="PayMonthlyButton"
                amount="$5"
                button-text="Pay monthly"
                suffix="/mo"
                @click="onChangePaymentPlan(paymentMonthlyValue)"
              />
              <payment-plan
                :active="paymentPlan === paymentYearlyValue"
                :title="
                  $vuetify.breakpoint.xsOnly ? 'Pay yearly*' : 'Annual fee*'
                "
                name="PayYearlyButton"
                amount="$50"
                button-text="Pay yearly"
                suffix="/yr"
                @click="onChangePaymentPlan(paymentYearlyValue)"
              />
            </payment-plan-wrapper>
            <typography type="small-p" class="payment-note">
              *Annual account fees will be charged to your first invoice each year.
            </typography>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <!-- Bank details -->
    <form-group
      v-if="paymentPlanComplete"
      :is-visible="currentQuestion === bankDetailsId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, bankDetailsId)
      "
    >
      <mobile-full-page-wrapper :id="bankDetailsId">
        <v-layout fill-height column justify-space-between>
          <!-- Bank details title -->
          <v-flex>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm12>
                <typography type="h5"
                  >What are your bank account details?</typography
                >
              </v-flex>
            </v-layout>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm12>
                <typography
                  :type="
                    $vuetify.breakpoint.xsOnly
                      ? 'small-p'
                      : 'small-caption-text'
                  "
                  light
                >
                  Payment is by direct debit 10 days following your invoice date each
                  month.
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- Bank details fields -->
          <v-flex>
            <!-- Bank name -->
            <v-layout>
              <v-flex md6 lg4 mb-2>
                <select-input
                  v-model="bankId"
                  name="BankName"
                  :items="bankNames"
                  :rules="[
                    () => $v.bankId.required || 'This field is required'
                  ]"
                  label="Bank name"
                />
              </v-flex>
            </v-layout>
            <!-- Bank account name -->
            <v-layout>
              <v-flex md9 lg6 mb-2>
                <text-input
                  v-model="bankAccountName"
                  name="BankAccountName"
                  :rules="[
                    () =>
                      $v.bankAccountName.required || 'This field is required',
                    () =>
                      $v.bankAccountName.maxLength ||
                      'Bank account name is too long'
                  ]"
                  label="Bank account name"
                  no-margin
                  @blur="$v.bankAccountName.$touch()"
                />
              </v-flex>
            </v-layout>
            <!-- Bank account number -->
            <v-layout>
              <v-flex md9 lg6>
                <text-input
                  v-model="bankAccountNumber"
                  name="BankAccountNumber"
                  :rules="[
                    () =>
                      $v.bankAccountNumber.required || 'This field is required',
                    () =>
                      $v.bankAccountNumber.minLength ||
                      'Bank account number not valid',
                    () =>
                      $v.bankAccountNumber.isValid ||
                      'Bank account number not valid'
                  ]"
                  label="Bank account number"
                  mask="##-####-#######-###"
                  pattern="\d*"
                  hint="00 - 0000 - 0000000 - 000"
                  @blur="$v.bankAccountNumber.$touch()"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="no-grow">
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mt-5>
              <v-flex xs10 sm6>
                <round-button
                  name="bankDetailsSectionNextButton"
                  v-if="!bankDetailsComplete"
                  :disabled="
                    $v.bankId.$invalid ||
                      $v.bankAccountName.$invalid ||
                      $v.bankAccountNumber.$invalid
                  "
                  outline
                  @click="onBankDetailsCompleteClick"
                  >Next</round-button
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <form-group
      v-if="bankDetailsComplete"
      :is-visible="currentQuestion === submitFormId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, submitFormId)
      "
    >
      <mobile-full-page-wrapper :id="submitFormId">
        <v-layout fill-height column justify-space-between>
          <!-- Bank details checkbox and submit application button button -->
          <v-flex>
            <!-- Bank details checkbox -->
            <v-layout>
              <v-flex :pr-5="$vuetify.breakpoint.lgAndUp" md8 lg8>
                <typography type="h3">
                  Great! Thanks for applying for a {{ brand }} account.
                </typography>

                <typography>
                  To confirm your application and move on to ordering cards
                  please select the
                  <strong>"Submit"</strong> button.
                </typography>
                <box>
                  <checkbox
                    v-model="hasBankSigningAuthority"
                    name="HasBankSigningAuthority"
                    label="I confirm that I have signing authority over this bank account"
                  />
                  <v-layout :pr-5="$vuetify.breakpoint.mdAndUp">
                    <checkbox
                      v-model="hasAcceptedTermsAndConditions"
                      name="hasAcceptedTermsAndConditions"
                    >
                      <div slot="label">
                        I have read and accepted the
                        <a
                          rel="noopener noreferrer"
                          :href="directDebitTermsLink"
                          name="DirectDebitTermsLink"
                          target="_blank"
                          @click.stop
                          >direct debit Terms and Conditions</a
                        >, the
                        <a
                          rel="noopener noreferrer"
                          :href="privacyPolicyLink"
                          name="PrivacyPolicyLink"
                          target="_blank"
                          @click.stop
                          >Privacy Policy</a
                        >
                        and the
                        <a
                          rel="noopener noreferrer"
                          :href="zTermsLink"
                          name="ZTermsLink"
                          target="_blank"
                          @click.stop
                          >Z Business Terms and Conditions</a
                        >
                      </div>
                    </checkbox>
                  </v-layout>
                </box>
              </v-flex>
            </v-layout>

            <!-- Submit application button -->
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm6 md12>
                <round-button
                  name="applyForApplicationButton"
                  :disabled="isDisabled"
                  :loading="submitApplicationProgress.inProgress"
                  @click="onSubmitApplication"
                  >Submit</round-button
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>
  </form-section>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import FormGroup from '@/components/atoms/FormGroup.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import SelectInput from '@/components/atoms/SelectInput.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import Box from '@/components/atoms/Box.vue';
import CardSettingsInfoBox from '@/components/molecules/CardSettingsInfoBox.vue';
import Autocomplete from '@/components/molecules/Autocomplete.vue';
import ToggleButton from '@/components/molecules/ToggleButton.vue';
import ToggleButtonWrapper from '@/components/atoms/ToggleButtonWrapper.vue';
import PaymentPlanWrapper from '@/components/atoms/PaymentPlanWrapper.vue';
import PaymentPlan from '@/components/molecules/PaymentPlan.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { SAVE_APPLICATION_ERROR } from '@/constants/errors';

import {
  SECTION_FINAL_DETAILS,
  DELIVERY_ADDRESS,
  CONTACT_NUMBER,
  INVOICE_EMAIL,
  INVOICE_DATE,
  PAYMENT_PLAN,
  BANK_DETAILS,
  SUBMIT_FORM,
  FIELD_ERROR_SELECTOR
} from '@/constants/html-ids';
import {
  DIRECT_DEBIT_TERMS_LINK,
  PRIVACY_POLICY_LINK,
  Z_TERMS_LINK,
  NEXT_STEPS,
  CREDIT_CHECK_FAILED
} from '@/constants/routes';
import { mapState, mapGetters } from 'vuex';
import {
  requiredIf,
  sameAs,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';
import { email, phone } from '@/validators';
import {
  PAYMENT_MONTHLY,
  PAYMENT_YEARLY,
  INVOICE_DATE_OPTIONS,
  CREDIT_CHECK_DECLINE,
  CREDIT_CHECK_APPROVE_WITH_PG,
  BRAND
} from '@/constants/form';
import { navigationOffset, navigationOffsetFor } from '@/helpers/navigation';
import api from '@/api';

export default {
  components: {
    Typography,
    Autocomplete,
    ToggleButton,
    ToggleButtonWrapper,
    MobileFullPageWrapper,
    FormGroup,
    TextInput,
    Checkbox,
    SelectInput,
    Box,
    RoundButton,
    FormSection,
    CardSettingsInfoBox,
    PaymentPlanWrapper,
    PaymentPlan
  },
  async mounted() {
    try {
      const response = await api.getBanks();

      this.bankNames = response.data.map(item => {
        return {
          text: item.name,
          value: item.bankId
        };
      });
    } catch (error) {
      this.$store.dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-retrieve-list-of-banks',
          text: SAVE_APPLICATION_ERROR
        },
        { root: true }
      );
    }
  },
  data() {
    return {
      bankNames: [],
      isLoading: false
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading ||
        this.sectionComplete ||
        this.$v.$invalid ||
        this.currentQuestion !== this.submitFormId ||
        !(this.fuelCardBusinessUseOnlyConfirm && this.fuelCardBusinessUseOnlyDeclare);
    },
    fuelCardBusinessUseOnlyConfirm: {
      get() {
        return this.$store.state.business.fuelCardBusinessUseOnlyConfirm;
      },
      set(value) {
        this.$store.dispatch('business/fuelCardBusinessUseOnlyConfirm', value);
      }
    },
    fuelCardBusinessUseOnlyDeclare: {
      get() {
        return this.$store.state.business.fuelCardBusinessUseOnlyDeclare;
      },
      set(value) {
        this.$store.dispatch('business/fuelCardBusinessUseOnlyDeclare', value);
      }
    },
    deliveryAddress: {
      get() {
        return this.$store.state.finalDetails.deliveryAddress;
      },
      set(value) {
        this.$store.dispatch('finalDetails/changeDeliveryAddress', value);
      }
    },
    deliveryAddressText: {
      get() {
        return this.$store.state.finalDetails.deliveryAddressText;
      },
      set(value) {
        this.$store.dispatch('finalDetails/changeDeliveryAddressText', value);
      }
    },
    contactNumber: {
      get() {
        return this.$store.state.finalDetails.contactNumber;
      },
      set(value) {
        this.$store.dispatch('finalDetails/changeContactNumber', value);
      }
    },
    invoiceEmail: {
      get() {
        return this.$store.state.finalDetails.invoiceEmail;
      },
      set(value) {
        this.$store.dispatch('finalDetails/changeInvoiceEmail', value);
      }
    },
    bankId: {
      get() {
        return this.$store.state.finalDetails.bankId;
      },
      set(value) {
        this.$store.dispatch('finalDetails/changeBankId', value);
      }
    },
    bankAccountName: {
      get() {
        return this.$store.state.finalDetails.bankAccountName;
      },
      set(value) {
        this.$store.dispatch('finalDetails/changeBankAccountName', value);
      }
    },
    bankAccountNumber: {
      get() {
        return this.$store.state.finalDetails.bankAccountNumber;
      },
      set(value) {
        this.$store.dispatch('finalDetails/changeBankAccountNumber', value);
      }
    },
    hasBankSigningAuthority: {
      get() {
        return this.$store.state.finalDetails.hasBankSigningAuthority;
      },
      set(value) {
        this.$store.dispatch(
          'finalDetails/changeHasBankSigningAuthority',
          value
        );
      }
    },
    hasAcceptedTermsAndConditions: {
      get() {
        return this.$store.state.finalDetails.hasAcceptedTermsAndConditions;
      },
      set(value) {
        this.$store.dispatch(
          'finalDetails/changeHasAcceptedTermsAndConditions',
          value
        );
      }
    },
    ...mapState({
      creditSectionComplete: state => state.credit.sectionComplete,
      deliveryAddressLookupResponse: state =>
        state.finalDetails.deliveryAddressLookupResponse,
      deliveryAddressLookupInProgress: state =>
        state.progress.deliveryAddressLookup.inProgress,
      deliveryAddressComplete: state =>
        state.finalDetails.deliveryAddressComplete,
      contactNumberComplete: state => state.finalDetails.contactNumberComplete,
      invoiceEmailComplete: state => state.finalDetails.invoiceEmailComplete,
      invoiceDateComplete: state => state.finalDetails.invoiceDateComplete,
      bankDetailsComplete: state => state.finalDetails.bankDetailsComplete,
      invoiceDate: state => state.finalDetails.invoiceDate,
      paymentPlan: state => state.finalDetails.paymentPlan,
      hasManualDeliveryAddress: state =>
        state.finalDetails.hasManualDeliveryAddress,
      submitApplicationProgress: state => state.progress.submitApplication,
      sectionComplete: state => state.finalDetails.sectionComplete
    }),
    ...mapGetters({
      currentQuestion: 'navigation/currentQuestion',
      skipPaymentPlan: 'offer/skipPaymentPlan',
      paymentPlanComplete: 'finalDetails/paymentPlanComplete'
    }),
    sectionId: () => SECTION_FINAL_DETAILS,
    deliveryAddressId: () => DELIVERY_ADDRESS,
    contactNumberId: () => CONTACT_NUMBER,
    invoiceEmailId: () => INVOICE_EMAIL,
    invoiceDateId: () => INVOICE_DATE,
    invoiceDateOptions: () => INVOICE_DATE_OPTIONS,
    paymentPlanId: () => PAYMENT_PLAN,
    bankDetailsId: () => BANK_DETAILS,
    submitFormId: () => SUBMIT_FORM,
    directDebitTermsLink: () => DIRECT_DEBIT_TERMS_LINK,
    zTermsLink: () => Z_TERMS_LINK,
    privacyPolicyLink: () => PRIVACY_POLICY_LINK,
    paymentMonthlyValue: () => PAYMENT_MONTHLY,
    paymentYearlyValue: () => PAYMENT_YEARLY,
    brand: () => BRAND
  },
  methods: {
    navigationOffset(htmlId) {
      if (this.$vuetify.breakpoint.smAndUp) {
        return navigationOffset(htmlId);
      }

      return 0;
    },
    navigationOffsetFor(selector) {
      if (this.$vuetify.breakpoint.smAndUp) {
        return navigationOffsetFor(selector);
      }

      return 0;
    },
    onChangeHasManualDeliveryAddress() {
      this.$store.dispatch('finalDetails/changeHasManualDeliveryAddress', true);
    },
    onChangeDeliveryAddressText(value) {
      this.$store.dispatch('finalDetails/changeDeliveryAddressText', value);
    },
    async onChangeInvoiceDate(value) {
      this.$store.dispatch('finalDetails/changeInvoiceDate', value);
      this.$store.dispatch('finalDetails/changeInvoiceDateComplete', true);

      await this.$nextTick();

      const nextSection = this.skipPaymentPlan ? BANK_DETAILS : PAYMENT_PLAN;

      this.$vuetify.goTo(`#${nextSection}`, {
        offset: this.navigationOffset(nextSection)
      });
    },
    onDeliveryAddressSearch() {
      this.$store.dispatch('finalDetails/deliveryAddressLookup');
    },
    async onDeliveryAddressCompleteClick() {
      this.$store.dispatch('finalDetails/changeDeliveryAddressComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${CONTACT_NUMBER}`, {
        offset: this.navigationOffset(CONTACT_NUMBER)
      });
    },
    async onContactNumberCompleteClick() {
      this.$store.dispatch('finalDetails/changeContactNumberComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${INVOICE_EMAIL}`, {
        offset: this.navigationOffset(INVOICE_EMAIL)
      });
    },
    async onInvoiceEmailCompleteClick() {
      this.$store.dispatch('finalDetails/changeInvoiceEmailComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${INVOICE_DATE}`, {
        offset: this.navigationOffset(INVOICE_DATE)
      });
    },

    async onChangePaymentPlan(value) {
      this.$store.dispatch('finalDetails/changePaymentPlan', value);

      await this.$nextTick();

      this.$vuetify.goTo(`#${BANK_DETAILS}`, {
        offset: this.navigationOffset(BANK_DETAILS)
      });
    },
    async onBankDetailsCompleteClick() {
      this.$store.dispatch('finalDetails/changeBankDetailsComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${SUBMIT_FORM}`, {
        offset: this.navigationOffset(SUBMIT_FORM)
      });
    },
    async onSubmitApplication() {
      this.isLoading = true;

      this.$store.dispatch('finalDetails/changeSectionComplete', true);
      await this.$store.dispatch('saver/submitApplication', true);

      // clear out toast state to prevent errors showing on card-preferences page
      this.$store.dispatch('ui/removeAllToasts');

      if (this.submitApplicationProgress.success) {
        // Get the credit check response
        const creditCheckResponse = await api.getCreditCheckDecision(
          this.$store.state.saver.applicationId
        );

        await this.$store.dispatch(
          'credit/changeCreditCheckResult',
          creditCheckResponse.data.status
        );

        if (creditCheckResponse.data.status === CREDIT_CHECK_DECLINE) {
          // Credit check failed
          this.$router.push(CREDIT_CHECK_FAILED);
        } else if (
          creditCheckResponse.data.status === CREDIT_CHECK_APPROVE_WITH_PG
        ) {
          // Credit check passed but business < threshold so show personal guarantee modal
          this.$store.dispatch('ui/togglePersonalGuaranteeDialog', true);
        } else {
          this.$router.push(NEXT_STEPS);
        }
      } else if (this.submitApplicationProgress.hasError) {
        await this.$nextTick();

        // Try scrolling back to the first error in the form.
        // NOTE:  This only works if there is a client-side error that have been skipped.

        // Check there is an error field or $vuetify.goTo will throw it's toys...
        if (document.querySelector(FIELD_ERROR_SELECTOR)) {
          this.$vuetify.goTo(FIELD_ERROR_SELECTOR, {
            offset: this.navigationOffsetFor(FIELD_ERROR_SELECTOR)
          });
        }
        this.$store.dispatch('finalDetails/changeSectionComplete', false);
      }
      this.isLoading = false;
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch(
          'navigation/addSectionInView',
          SECTION_FINAL_DETAILS
        );
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_FINAL_DETAILS
        );
      }
    },
    onChangeQuestionVisibility(isVisible, htmlId) {
      if (isVisible) {
        this.$store.dispatch('navigation/addQuestionInView', htmlId);
      } else {
        this.$store.dispatch('navigation/removeQuestionInView', htmlId);
      }
    }
  },
  validations: {
    deliveryAddress: {
      required: requiredIf(nestedModel => !nestedModel.hasManualDeliveryAddress)
    },
    deliveryAddressText: {
      required: requiredIf(nestedModel => nestedModel.hasManualDeliveryAddress)
    },
    contactNumber: {
      required: requiredIf(nestedModel => nestedModel.deliveryAddressComplete),
      phone
    },
    invoiceEmail: {
      email,
      required: requiredIf(nestedModel => nestedModel.contactNumberComplete)
    },
    invoiceDate: {
      required: requiredIf(nestedModel => nestedModel.invoiceEmailComplete)
    },
    paymentPlan: {
      required: requiredIf(
        nestedModel =>
          nestedModel.invoiceDateComplete && !nestedModel.skipPaymentPlan
      )
    },
    bankId: {
      required: requiredIf(nestedModel => nestedModel.paymentPlanComplete)
    },
    bankAccountName: {
      required: requiredIf(nestedModel => nestedModel.paymentPlanComplete),
      maxLength: maxLength(250)
    },
    bankAccountNumber: {
      required: requiredIf(nestedModel => nestedModel.paymentPlanComplete),
      minLength: minLength(15),
      async isValid(value) {
        if (value === '') {
          return true;
        }

        if (value.length < 15) return false;

        try {
          await api.validateBankAccountNumber(this.bankId, value);
        } catch (error) {
          // If the API is down
          if (!error.response) {
            this.$store.dispatch(
              'ui/addErrorToast',
              {
                id: 'failed-validate-bank-details',
                text: SAVE_APPLICATION_ERROR
              },
              { root: true }
            );
          }
          return false;
        }

        return true;
      }
    },
    hasBankSigningAuthority: {
      sameAs: sameAs(nestedModel => {
        if (nestedModel.currentQuestion !== nestedModel.submitFormId) {
          // If not up to the section, can be the current value
          return nestedModel.hasBankSigningAuthority;
        }

        // Else should be checked
        return true;
      })
    },
    hasAcceptedTermsAndConditions: {
      sameAs: sameAs(nestedModel => {
        if (nestedModel.currentQuestion !== nestedModel.submitFormId) {
          // If not up to the section, can be the current value
          return nestedModel.hasAcceptedTermsAndConditions;
        }

        // Else should be checked
        return true;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.payment-note {
  color: $note-color;
}
</style>
