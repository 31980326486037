<template>
  <div class="slider-input-summary">
    <text-input
      :name="name"
      :value="value"
      :prefix="prefix"
      :suffix="suffix"
      bold
      class="slider-input-summary__input"
      mask="#########"
      color="white"
      type="number"
      no-margin
      @input="onInput"
      @keydown="onKeyDown"
    />
    <typography class="slider-input-summary__text">{{ text }}</typography>
  </div>
</template>

<script>
import TextInput from '@/components/atoms/TextInput.vue';
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    TextInput,
    Typography
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    prefix: {
      type: null,
      default: null
    },
    suffix: {
      type: null,
      default: null
    },
    text: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    }
  },
  methods: {
    onKeyDown(ev) {
      // Prevent keys from being entered
      if (
        ev.keyCode === 69 || // 'e'
        ev.keyCode === 189 ||
        ev.keyCode === 109 || // '-'
        ev.keyCode === 190 ||
        ev.keyCode === 110 || // '.'
        ev.keyCode === 187 ||
        ev.keyCode === 107 // +
      ) {
        ev.preventDefault();
        return;
      }
    },
    onInput(value) {
      this.$emit('input', Number(value));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.slider-input-summary {
  display: inline-flex;
  align-items: center;
  color: $white;
  background-color: $mid-orange;
  border-radius: rem(5px);
  padding: rem(10px 20px);
  margin-bottom: rem(40px);

  &__input {
    margin: 0;
    margin-right: rem(8px);

    /deep/ input {
      width: rem(95px);
    }

    /deep/ .v-messages {
      min-height: 0 !important;
    }
  }

  &__text {
    margin-bottom: 0;
  }
}
</style>
