<template>
  <div class="fuel-offer">
    <typography :type="$vuetify.breakpoint.xsOnly ? 'body' : 'h5'" light
      >At {{ item.deliveryNetworks }} {{ item.siteType }}</typography
    >
    <typography type="h2" class="fuel-offer__large-text">
      Save {{ item.amountCpl }}c
      <span class="fuel-offer__orange-text">per litre</span>
    </typography>
    <typography :type="$vuetify.breakpoint.xsOnly ? 'body' : 'h5'" light
      >off pump price</typography
    >
    <typography v-if="validToDate" type="small-p" light>
      This offer is valid until
      {{ validToDate }}
    </typography>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import { formatDateTime } from '@/helpers/date';
export default {
  components: {
    Typography
  },
  props: {
    item: Object
  },
  computed: {
    validToDate: function() {
      return formatDateTime(this.$store.state.offer.validToDate);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.fuel-offer {
  color: $white;
  text-align: center;
  padding: rem(40px 30px);
  width: calc(100% + #{rem($container-mobile-padding * 2)});
  margin: rem(
    10px -#{$container-mobile-padding} 40px -#{$container-mobile-padding}
  );

  @include sm {
    width: 100%;
    padding: rem(60px);
    margin: rem(0 0 50px 0);
    border-radius: rem(20px);
  }
  &__large-text {
    margin-bottom: rem(15px);
  }
  &__orange-text {
    color: $mid-orange;
  }
}
</style>
