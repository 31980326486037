<template>
  <div class="page">
    <your-business />
    <your-offer />
    <credit-check-details />
    <final-details />
  </div>
</template>

<script>
import YourBusiness from '@/components/organisms/YourBusiness.vue';
import YourOffer from '@/components/organisms/YourOffer.vue';
import CreditCheckDetails from '@/components/organisms/CreditCheckDetails.vue';
import FinalDetails from '@/components/organisms/FinalDetails.vue';
import { LANDING } from '@/constants/routes';
import { navigationOffset } from '@/helpers/navigation';

export default {
  components: {
    YourBusiness,
    YourOffer,
    CreditCheckDetails,
    FinalDetails
  },
  props: {
    applicationId: String,
    draftId: String
  },
  async created() {
    if (this.applicationId && this.draftId) {
      await this.$store.dispatch('saver/loadSavedForm', {
        applicationId: this.applicationId,
        draftId: this.draftId
      });

      // Send the user back to the start if the draft id is not valid
      if (this.$store.state.saver.applicationId === '') {
        this.$router.push(LANDING);
        return;
      }

      // Send the user to the top of the page to fix visibility issue
      // after restoring state
      this.$store.dispatch('navigation/changeMiddleOfPage', 0);

      // figure out what section to go to and scroll to it
      const questionsInView =
        this.$store.state.navigation &&
        this.$store.state.navigation.questionsInView;

      const questionId = Array.isArray(questionsInView) && questionsInView[0];
      if (questionId) {
        this.$vuetify.goTo(`#${questionId}`, {
          offset: -navigationOffset(questionId)
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.page {
  position: relative;
  color: $charcoal;
  padding: rem(0 20px);

  @include sm {
    padding: rem(0);
  }
}
</style>
