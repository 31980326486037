<template>
  <v-menu
    v-model="showCalendar"
    :close-on-content-click="false"
    lazy
    transition="scale-transition"
    offset-y
    :nudge-right="40"
    class="date-picker"
  >
    <text-input
      slot="activator"
      mask="## / ## / ####"
      hint="DD / MM / YYYY"
      type="text"
      class="date-picker__input"
      :name="name"
      :value="textValue"
      :label="label"
      :error="error"
      :error-messages="errorMessages"
      :no-margin="noMargin"
      :rules="inputRules"
      :validate-on-blur="validateOnBlur"
      @input="onTextInput"
    >
    </text-input>
    <v-date-picker
      ref="picker"
      header-color="accent"
      class="date-picker__calendar"
      :value="value"
      :max="max"
      :min="min"
      :allowed-dates="allowedDates"
      :type="type"
      @input="onDateChange"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import TextInput from '@/components/atoms/TextInput.vue';
import { formatDate } from '@/helpers/date';

const DATE_FORMAT = 'yyyy-MM-dd';
const TEXT_FORMAT = 'ddMMyyyy';

export default {
  components: {
    TextInput
  },
  data: () => ({
    showCalendar: false,
    textValue: null
  }),
  props: {
    allowedDates: {
      type: Function,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    },
    rules: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'date'
    },
    activePicker: {
      type: String,
      default: undefined,
      validator: value => {
        return ['YEAR', 'MONTH'].includes(value);
      }
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    validateOnBlur: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    showCalendar(val) {
      // Change the default calendar view if specified.
      // Ugly, but it's how they do it on the Vuetify site - https://vuetifyjs.com/en/components/date-pickers
      if (val && this.activePicker) {
        this.$nextTick(
          () => (this.$refs.picker.activePicker = this.activePicker)
        );
      }
    }
  },
  computed: {
    inputRules() {
      // Hack to fix validation errors appearing when the text input loses focus to the calendar.
      // To prevent the validators firing when the text input loses focus, we disabled the validation
      // rules while the calendar is visible. It's not pretty but it does the job.
      return this.showCalendar ? [] : this.rules;
    }
  },
  methods: {
    onDateChange(value) {
      this.showCalendar = false;
      // Set the text value.
      this.textValue = formatDate(value);
      this.$emit('input', value);
    },
    onTextInput(value) {
      // Hide the calendar.
      this.showCalendar = false;
      const parsedValue = formatDate(value, DATE_FORMAT, TEXT_FORMAT);
      this.$emit('input', parsedValue);
    }
  },
  mounted() {
    // Set text if value is set
    if (this.value) this.textValue = formatDate(this.value);
  }
};
</script>

<style lang="scss" scoped></style>
