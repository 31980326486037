<template>
  <div class="text-input-with-button">
    <v-text-field
      :browser-autocomplete="autocomplete"
      :value="value"
      :error="error"
      :rules="rules"
      :mask="mask"
      :placeholder="placeholder"
      :error-messages="errorMessages"
      :hint="hint"
      :type="type"
      :class="['text-input']"
      solo
      @input="onChange"
      @blur="onBlur"
      @keyup.enter="onClick"
    />
    <v-btn class="button" depressed @click="onClick">
      <typography v-if="!loading" inline class="button-text">{{
        buttonText
      }}</typography>
      <loading-dots v-else />
    </v-btn>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import LoadingDots from '@/components/atoms/LoadingDots.vue';

export default {
  components: {
    Typography,
    LoadingDots
  },
  props: {
    autocomplete: {
      type: String,
      default: 'off'
    },
    hint: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    value: {
      type: null,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    buttonText: {
      type: String,
      default: 'Submit'
    },
    rules: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Keep a local copy of value to emit when updating on clicking button instead of on keypress
      text: this.value
    };
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
      this.text = value;
    },
    onBlur(event) {
      this.$emit('blur', event.target.value);
    },
    onClick() {
      this.$emit('click', this.text);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.text-input-with-button {
  display: flex;
  margin-bottom: rem(25px);
}

.button {
  margin: 0;
  height: auto;
  background-color: $mid-orange !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: rem(100px);
  color: $white;
  height: 48px;

  &:before {
    opacity: 0;
  }
}

.button-text {
  text-transform: none;
}

.text-input {
  font-size: rem(20px);
  line-height: rem(30px);
  margin-bottom: 0;

  /deep/ .v-input__control .v-input {
    &__slot {
      background-color: #f4f4f4;
      box-shadow: none !important;
      padding: rem(0 20px);
      margin-bottom: 0;
      border-radius: 0;
      border-top-left-radius: rem(3px);
      border-bottom-left-radius: rem(3px);
    }
  }

  /deep/ .v-text-field__details {
    // position: absolute;
    // top: calc(100% + 8px);
    padding-top: rem(5px);
    padding-left: rem(5px);

    .v-messages {
      font-size: rem(15px);
      min-height: rem(15px);

      &.error--text {
        color: $error-color;
      }
    }
  }

  /deep/ input {
    color: $charcoal !important;

    // Transparent autocomplete background
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      animation: autofill 0s forwards;
    }

    @include placeholder {
      color: #d2d2d2;
      opacity: 1;
    }

    @keyframes autofill {
      100% {
        background: transparent;
        color: inherit;
      }
    }
  }

  /deep/ input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.error--text {
    /deep/ .v-input__slot {
      border: 1px solid $error-color;
      background-color: #ffe8e9;

      input {
        color: $error-color !important;
      }
    }
  }
}
</style>
